import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import Center from 'src/components/Center';

import { LoaderProps } from './Loader.d';

const Loader = (props: LoaderProps) => {
    const { text } = props;

    return (
        <Center>
            <CircularProgress />
            <Typography variant={'body1'} children={text} />
        </Center>
    );
};

export default Loader;