import React, { SyntheticEvent } from 'react';

import getNestedValue from 'src/components/utils/getNestedValue';
import getOptionLabel from 'src/components/utils/getOptionLabel';
import hasNestedValue from 'src/components/utils/hasNestedValue';
import setNestedValue from 'src/components/utils/setNestedValue';

import ApiSelect from 'src/components/fields/ApiSelect';
import { TData } from 'src/components/crud/Crud.d';

import { CrudApiSelectProps } from './CrudApiSelect.d';

const CrudApiSelect = <T extends TData>(props: CrudApiSelectProps<T>) => {
    const { column, values, setValues, errors, readOnly = false, required = false, size = 'medium' } = props;

    const { field, header } = column;

    const {
        labelKey,
        name,
        endpoint,
        multiple = false,
        default: defaultVal,
        queryParams,
        placeholder,
        inputProps,
        inputAction,
    } = field;

    return (
        <ApiSelect
            label={placeholder ? '' : header}
            placeholder={placeholder}
            endpoint={endpoint}
            size={size}
            value={getNestedValue(values, name, defaultVal)}
            onChange={(event: SyntheticEvent, val: string) => {
                setValues(setNestedValue(values, name, val));
            }}
            queryParams={queryParams}
            multiple={multiple}
            getOptionLabel={(option: T) => getOptionLabel(option, labelKey)}
            error={hasNestedValue(errors, name)}
            helperText={getNestedValue(errors, name, '')}
            readOnly={readOnly}
            required={required}
            inputProps={inputProps}
            inputAction={inputAction}
        />
    );
};

export default CrudApiSelect;
