import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';
import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns } from 'src/components/crud/Crud.d';

import getIdLabel from 'src/components/utils/getIdLabel';

import { OperationCell } from './Operation.d';
import { Requisition } from './Requisition.d';
import { StatusIcon } from './Status.d';
import RequisitionModal from './RequisitionModal';

export const name = {
    singular: 'Requisição',
    plural: 'Requisições',
};

export const columns: Columns<Requisition> = [
    {
        accessorKey: 'status',
        header: '',
        Cell: (cell: any) => <StatusIcon status={cell.row.original.status} />,
        size: 20,
        grow: false,
        enableSorting: false,
        enableResizing: false,
        enableColumnFilter: false,
        enableColumnActions: false,
    },
    {
        accessorKey: 'id',
        header: 'Id',
        filterLookup: 'exact',
        Cell: ({ row }) => getIdLabel(row.original.id),
    },
    {
        accessorKey: 'operation',
        header: 'Operação',
        Cell: (cell: any) => <OperationCell status={cell.row.original.operation} />,
    },
    {
        accessorKey: 'workorder.id',
        header: 'Ordem de Serviço',
        Cell: ({ row }) => getIdLabel(row.original.workorder.id),
    },
    {
        accessorKey: 'employee.name',
        header: 'Solicitante',
    },
];

export const endpoint = '/api/v0/catalog/products/requisitions/';

const RequisitionsPage = () => {
    /**
     * MODAL CONTROL
     */
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEntity, setModalEntity] = useState<Requisition | null>(null);
    const [refresh, setRefresh] = useState(false);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Almoxarifado', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.Requisitions}
                endpoint={endpoint}
                columns={columns}
                // Features
                controlledRefresh={refresh}
                setControlledRefresh={setRefresh}
                handleCreateUpdate={(entity: Requisition | null) => {
                    setModalOpen(true);
                    setModalEntity(entity);
                }}
                crudEnableDelete={false}
            />

            <RequisitionModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setModalEntity(null);
                    setRefresh(!refresh);
                }}
                requisition={modalEntity}
            />
        </>
    );
};

export default RequisitionsPage;
