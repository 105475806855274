import React from 'react';

import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import SvgIcon from '@mui/material/SvgIcon/SvgIcon';

import InIcon from '@mui/icons-material/Login';
import OutIcon from '@mui/icons-material/Logout';

export const OPERATION = {
    IN: 1,
    OUT: -1,
};

export type Operation = (typeof OPERATION)[keyof typeof OPERATION];

export const OPERATION_LABELS: Record<Operation, string> = {
    [OPERATION.IN]: 'Entrada',
    [OPERATION.OUT]: 'Saída',
};

export const OPERATION_COLORS: Record<Operation, 'warning' | 'info' | 'success' | 'error'> = {
    [OPERATION.IN]: 'warning',
    [OPERATION.OUT]: 'info',
};

export const OPERATION_ICONS: Record<Operation, typeof SvgIcon> = {
    [OPERATION.IN]: InIcon,
    [OPERATION.OUT]: OutIcon,
};

export const OperationCell = (props: { status: Operation }) => {
    const { status } = props;

    const Icon = OPERATION_ICONS[status];
    return (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Icon fontSize={'small'} color={OPERATION_COLORS[status]} />
            <Typography children={OPERATION_LABELS[status]} />
        </Stack>
    );
};

export const OperationIcon = (props: { status: Operation }) => {
    const { status } = props;

    const Icon = OPERATION_ICONS[status];
    return (
        <Tooltip title={OPERATION_LABELS[status]}>
            <Icon color={OPERATION_COLORS[status]} />
        </Tooltip>
    );
};
