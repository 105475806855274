import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ClassIcon from '@mui/icons-material/Bookmarks';
import CloseIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Assignment';
import MeasurementsIcon from '@mui/icons-material/PendingActions';
import ServiceIcon from '@mui/icons-material/HomeRepairService';
import SaveIcon from '@mui/icons-material/Save';

import api from 'src/services/api';

import CrudApiSelect from 'src/components/crud/fields/CrudApiSelect';
import CrudSelect from 'src/components/crud/fields/CrudSelect';
import CrudText from 'src/components/crud/fields/CrudText';

import ModalSection from 'src/components/ModalSection';
import { TData } from 'src/components/crud/Crud.d';
import { concatFieldErrors } from 'src/components/crud/modals/CreateUpdateModal';

import { ServiceModalProps } from 'src/pages/catalog/services/Service/ServiceModal/ServiceModal.d';
import getIdLabel from 'src/components/utils/getIdLabel';

const defaultValues = {
    id: 0,
    description: '',
    group: '',
    subgroup: '',
    avg_time: '',
    avg_hour_cost: '',
    is_active: true,
};

const ServiceModal = (props: ServiceModalProps) => {
    const { open, onClose, service } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * VALUES CONTROL
     */
    const [values, setValues] = useState<TData>(defaultValues);
    const [errors, setErrors] = useState({});

    const valuesControl = {
        values,
        setValues,
        errors,
        setErrors,
    };

    /**
     * DATA LOAD
     */
    useEffect(() => {
        if (open) {
            if (service) {
                setValues({
                    ...Object.fromEntries(
                        Object.entries(service).map(([key, value]) => [key, value === null ? '' : value])
                    ),
                    id: service.id,
                    group: service.subgroup?.group ?? '',
                } as TData);
            } else {
                setValues(defaultValues);
            }
        }
    }, [service]);

    /**
     * VALUES SUBMIT
     */
    const submitChanges = async () => {
        const data: Record<string, any> = {
            ...values,
        };

        // convert nested objects to id
        ['subgroup'].forEach((key) => {
            if (key in data) {
                data[key] = !data[key] || data[key].id === 0 || typeof data[key] !== 'object' ? null : data[key].id;
            }
        });

        delete data['group'];

        // clear empty fields
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });

        if (service) {
            return await api.put(`/api/v0/catalog/services/${service.id}/`, data).then((response) => response.data);
        }

        return await api.post('/api/v0/catalog/services/', data).then((response) => response.data);
    };

    const { mutate: handleSave, isPending } = useMutation({
        mutationFn: submitChanges,
        throwOnError: false,
        onError: (error: AxiosError<any>) => {
            try {
                let detail = null;
                let errors = error.response?.data as Record<string, string[]>;

                if (error.response?.data.hasOwnProperty('detail')) {
                    detail = errors.detail;
                    delete errors.detail;
                }

                let fieldErrors = concatFieldErrors(errors);

                setErrors(fieldErrors);

                if (detail) {
                    enqueueSnackbar(detail, { variant: 'error' });
                }
            } catch (e) {
                enqueueSnackbar('Erro ao salvar!', { variant: 'error' });
            }
        },
        onSuccess: () => {
            enqueueSnackbar('Serviço salvo com sucesso.', { variant: 'success' });
            setErrors({});
            onClose();
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Dialog open={open} maxWidth={'lg'} fullWidth>
                <DialogContent sx={{ height: '90vh' }}>
                    <Stack direction={'column'} sx={{ pb: 3 }}>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            sx={{
                                pb: 2.5,
                            }}
                        >
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <ServiceIcon />
                                <Typography
                                    variant={'h6'}
                                    fontWeight={500}
                                    children={service ? `Serviço ${getIdLabel(service.id)}` : 'Novo Serviço'}
                                />
                            </Stack>

                            <Box>
                                <IconButton onClick={onClose} children={<CloseIcon />} disabled={isPending} />
                            </Box>
                        </Stack>

                        <ModalSection title={'Identificação do Serviço'} icon={InfoIcon} defaultExpanded>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'description',
                                            header: 'Descrição',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </ModalSection>

                        <ModalSection title={'Classificação'} icon={ClassIcon} defaultExpanded>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'group.description',
                                            header: 'Grupo',
                                            field: {
                                                type: 'search',
                                                name: 'group',
                                                endpoint: '/api/v0/catalog/services/groups/',
                                                labelKey: (e) => (e ? e.description : ''),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        readOnly={!values.group?.id}
                                        column={{
                                            accessorKey: 'subgroup.description',
                                            header: 'Subgrupo',
                                            field: {
                                                type: 'search',
                                                name: 'subgroup',
                                                endpoint: '/api/v0/catalog/services/subgroups/',
                                                labelKey: (e) => (e ? e.description : ''),
                                                queryParams: {
                                                    group__id: values.group?.id,
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </ModalSection>

                        <ModalSection title={'Medidas'} icon={MeasurementsIcon} defaultExpanded>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'avg_time',
                                            header: 'Tempo médio (min)',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'avg_hour_cost',
                                            header: 'Custo/Hora médio',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </ModalSection>
                    </Stack>
                </DialogContent>

                <DialogActions
                    sx={{
                        py: 1.5,
                        px: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderTop: '1px solid #E0E0E0',
                        bgcolor: '#F9F9F9',
                    }}
                >
                    <Box width={150}>
                        <CrudSelect
                            {...valuesControl}
                            column={{
                                accessorKey: 'is_active',
                                header: 'Status',
                                field: {
                                    size: 'small',
                                    type: 'select',
                                    options: {
                                        true: 'Ativo',
                                        false: 'Inativo',
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Stack direction={'row'} spacing={1} sx={{ p: 1 }}>
                        <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                        <Button
                            variant={'contained'}
                            children={'Salvar'}
                            onClick={() => handleSave()}
                            disabled={isPending}
                            startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                        />
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ServiceModal;
