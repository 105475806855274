export const brlFormatter = (value: any) => {
    if (!value && value !== 0) {
        return '';
    }

    return parseFloat(value).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
    });
};
