import React, { createContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AppRoutes from 'src/routes/appRoutes';

// components
import RouteWithSidebar from './components/RouteWithSidebar/RouteWithSidebar';
import RouteWithTenant from './components/RouteWithTenant/RouteWithTenant';
import RouteOfSystem from './components/RouteOfSystem';
import Preloader from 'src/components/Preloader';

// service
import { getCachedSuperUser, getPermissions } from 'src/services/storage/permissions';
import { getUserData, User } from 'src/services/storage/user';

// pages
import NotFound from 'src/pages/errors/NotFound';
import Settings from 'src/pages/user/Settings';
import Workorder from 'src/pages/maintenances/Workorder';

import PrivatePages from './privatePages';

export const UserContext = createContext<User | null>(null);

function PrivateAppRoutes() {
    const [loading, setLoading] = useState(true);

    /**
     * LOAD PERMISSIONS
     */
    const [user, setUser] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [superuser, setSuperuser] = useState(false);

    useEffect(() => {
        getPermissions().then((permissions) => {
            getUserData().then((user) => setUser(user));
            setPermissions(permissions ?? []);
            setSuperuser(getCachedSuperUser() ?? false);
            setTimeout(() => setLoading(false), 1000);
        });
    }, []);

    return (
        <>
            <Preloader show={loading} />

            <UserContext.Provider value={user}>
                <Routes>
                    {/* user */}
                    <Route path={AppRoutes.UserSettings.path} element={<RouteWithSidebar component={Settings} />} />

                    {PrivatePages.map((page, index) => {
                        return (
                            <Route
                                key={index}
                                path={page.route.path}
                                element={<RouteOfSystem page={page} superuser={superuser} permissions={permissions} />}
                            />
                        );
                    })}

                    {/* redirect */}
                    <Route path={'/'} element={<Navigate to={AppRoutes.Home.path} />} />

                    {/* error pages */}
                    <Route path={'*'} element={<NotFound />} />
                </Routes>
            </UserContext.Provider>
        </>
    );
}

export default function PrivateRoutes() {
    return (
        <RouteWithTenant>
            <PrivateAppRoutes />
        </RouteWithTenant>
    );
}
