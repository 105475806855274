export const CATEGORIES = {
    PREVENTIVE: 1,
    CORRECTIVE: 2,
    PROGRAMMED: 3,
};

export const CATEGORIES_LABELS = {
    [CATEGORIES.PREVENTIVE]: 'Preventiva',
    [CATEGORIES.CORRECTIVE]: 'Corretiva',
    [CATEGORIES.PROGRAMMED]: 'Programada',
};

export type Category = (typeof CATEGORIES)[keyof typeof CATEGORIES];
