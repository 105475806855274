import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';

import styled from '@mui/material/styles/styled';

import api from 'src/services/api';

import { ApiSelectProps } from './ApiSelect.d';

const StyledTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'hasAction',
})<{ hasAction: number }>(({ hasAction }) => ({
    ...(hasAction
        ? {
              '& .MuiOutlinedInput-root': {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
              },
          }
        : {}),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    textTransform: 'lowercase',
}));

const ApiSelect = (props: ApiSelectProps) => {
    const {
        endpoint,
        error,
        getOptionLabel,
        helperText,
        label,
        placeholder,
        multiple,
        onChange,
        readOnly,
        required,
        value,
        queryParams,
        inputProps = {},
        inputAction,
        size = 'medium',
    } = props;

    /**
     * HANDLE SEARCH TEXT CONTROL
     */
    const [inputSearchText, setInputSearchText] = useState('');
    const [searchText, setSearchText] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const typingTimeoutRef = useRef<any>(null);

    useEffect(() => {
        if (value && value.length) {
            return;
        }

        if (typingTimeoutRef.current) {
            setIsTyping(true);
            clearTimeout(typingTimeoutRef.current);
        }

        if (inputSearchText === '') {
            setIsTyping(false);
            setSearchText('');
            return;
        }

        typingTimeoutRef.current = setTimeout(() => {
            setIsTyping(false);
            setSearchText(inputSearchText);
        }, 750);

        return () => {
            clearTimeout(typingTimeoutRef.current);
        };
    }, [inputSearchText]);

    /**
     * FETCH API CONTROL
     */
    const fetchOptions = async () => {
        return await api
            .get(endpoint, {
                params: {
                    ...queryParams,
                    search: searchText,
                },
            })
            .then((res) => res.data.results);
    };

    const { data: options, isLoading } = useQuery({
        queryKey: [endpoint, queryParams, searchText],
        queryFn: fetchOptions,
        enabled: !!(!readOnly && endpoint && !(value && value.length)),
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Autocomplete
            multiple={multiple}
            filterOptions={(x) => x}
            openText={'Abrir'}
            closeText={'Fechar'}
            clearText={'Remover'}
            loadingText={'Carregando...'}
            noOptionsText={inputSearchText !== '' ? 'Sem opções para o filtro.' : 'Pesquisar...'}
            loading={isLoading}
            options={options ?? []}
            isOptionEqualToValue={(option: Record<string, any>, val: Record<string, any> | null) =>
                !!val && !!val.id && option.id.toString() === val.id.toString()
            }
            getOptionLabel={(option) => getOptionLabel(option)}
            value={value}
            onInputChange={(event: SyntheticEvent, newSearchText: string) => {
                setInputSearchText(newSearchText);
            }}
            onChange={(event, val) => onChange(event, val)}
            disabled={readOnly}
            renderInput={(params) => (
                <FormGroup row>
                    <StyledTextField
                        {...params}
                        placeholder={placeholder}
                        label={label}
                        size={size}
                        variant={'outlined'}
                        required={required && (multiple ? value?.length === 0 : !value)}
                        error={error}
                        helperText={helperText}
                        hasAction={inputAction ? 1 : 0}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {(isTyping || isLoading) && (
                                        <CircularProgress
                                            size={20}
                                            color={isTyping ? 'info' : 'inherit'}
                                            disableShrink={isTyping}
                                        />
                                    )}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        {...inputProps}
                        sx={{
                            flex: 10,
                        }}
                    />

                    {inputAction && (
                        <StyledButton
                            variant={'contained'}
                            disableElevation
                            sx={{
                                backgroundColor: '#444',
                            }}
                            onClick={inputAction.onClick}
                            children={inputAction.label}
                        />
                    )}
                </FormGroup>
            )}
        />
    );
};

export default ApiSelect;
