import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns } from 'src/components/crud/Crud.d';

import { Subgroup } from './Subgroup.d';

const name = {
    singular: 'Subgrupo de serviço',
    plural: 'Subgrupo de serviço',
};

const columns: Columns<Subgroup> = [
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
    {
        accessorKey: 'group.description',
        header: 'Grupo',
        field: {
            type: 'search',
            name: 'group',
            endpoint: '/api/v0/catalog/services/groups/',
            labelKey: (e) => (e ? e.description : ''),
        },
    },
];

const SubgroupsPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Manutenção', 'Cadastros', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.ServiceSubgroups}
                endpoint={'/api/v0/catalog/services/subgroups/'}
                columns={columns}
            />
        </>
    );
};

export default SubgroupsPage;
