import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ChangeIcon from '@mui/icons-material/ImportExport';
import CloseIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';

import api from 'src/services/api';

import ApiSelect from 'src/components/fields/ApiSelect';

import { ASSET_TYPES } from 'src/pages/fleet/Fleet.d';
import { Equipment } from 'src/pages/fleet/Equipment';

import { AddMountingModalProps } from './AddMountingModal.d';

const AddMountingModal = (props: AddMountingModalProps) => {
    const { open, onClose, asset } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * VALUES
     */
    const [equipment, setEquipment] = useState<Equipment | null>(null);

    /**
     * HANDLE SUBMIT
     */
    const submitMountingAdd = async () => {
        if (!asset || !equipment) return;

        return await api.post('/api/v0/fleet/allocations/current/mounting/', {
            asset: asset.id,
            equipment: equipment.id,
        });
    };

    const { mutate, isPending } = useMutation({
        mutationFn: submitMountingAdd,
        onSuccess: () => {
            onClose();
        },
        onError: (error: AxiosError<any>) => {
            enqueueSnackbar(error?.response?.data?.detail || 'Erro ao adicionar vínculo de instalação', {
                variant: 'error',
            });
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
            <DialogContent>
                <Stack direction={'row'} justifyContent={'space-between'} mb={1.5}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <ChangeIcon />
                        <Typography variant={'h6'} fontWeight={500} children={`Adicionar vínculo de instalação`} />
                    </Stack>

                    <Box>
                        <IconButton onClick={onClose} children={<CloseIcon />} />
                    </Box>
                </Stack>

                <Grid container spacing={2} mb={2}>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={8}>
                            <ApiSelect
                                size={'small'}
                                label={'Ativo'}
                                endpoint={'/api/v0/fleet/assets/'}
                                value={equipment}
                                onChange={(e, v) => setEquipment(v)}
                                getOptionLabel={(v) => v.description}
                                readOnly={!asset?.asset_type}
                                queryParams={{
                                    asset_type: ASSET_TYPES.EQUIPMENT,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Typography color={'error'} variant={'caption'}>
                    * Esta ação não pode ser revertida.
                </Typography>
            </DialogContent>
            <DialogActions
                sx={{
                    py: 1.5,
                    px: 3,
                    display: 'flex',
                    justifyContent: 'end',
                    borderTop: '1px solid #E0E0E0',
                    bgcolor: '#F9F9F9',
                }}
            >
                <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                <Button
                    variant={'contained'}
                    children={'Salvar'}
                    onClick={() => mutate()}
                    disabled={isPending}
                    startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                />
            </DialogActions>
        </Dialog>
    );
};

export default AddMountingModal;
