import React, { SyntheticEvent, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Clear';
import PlanIcon from '@mui/icons-material/Checklist';
import SaveIcon from '@mui/icons-material/Save';

import ApiSelect from 'src/components/fields/ApiSelect';
import getOptionLabel from 'src/components/utils/getOptionLabel';

import { Plan } from 'src/pages/catalog/Plan';
import { Service } from 'src/pages/catalog/services/Service';

import api from 'src/services/api';

import { AddServiceModalProps } from './AddServiceModal.d';

const AddServiceModal = (props: AddServiceModalProps) => {
    const { open, onClose, workorderId, onAdd, workorderPlanIds, assetModelId, assetType } = props;

    const [selectedPlans, setSelectedPlans] = useState<Plan[]>([]);
    const [selectedServices, setSelectedServices] = useState<Service[]>([]);

    /**
     * SUBMIT
     */
    const submit = async () => {
        return await api
            .post(`/api/v0/maintenances/workorders/${workorderId}/tasks/add/`, {
                plans: selectedPlans.map((plan) => plan.id),
                services: selectedServices.map((service) => service.id),
            })
            .then((res) => res.data);
    };

    const { mutate, isPending } = useMutation({
        mutationFn: submit,
        onSuccess: (data) => {
            onAdd(data);
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog
            fullWidth
            open={open}
            onClose={onClose}
            component={'form'}
            onSubmit={(e) => {
                e.preventDefault();
                mutate();
            }}
        >
            <DialogContent>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    sx={{
                        pb: 2.5,
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <PlanIcon />
                        <Typography variant={'h6'} fontWeight={500} children={'Adicionar serviços'} />
                    </Stack>

                    <Box>
                        <IconButton onClick={onClose} children={<CloseIcon />} disabled={isPending} />
                    </Box>
                </Stack>

                <Stack spacing={2}>
                    <ApiSelect
                        label={'Planos de manutenção'}
                        endpoint={'/api/v0/catalog/plans/'}
                        value={selectedPlans}
                        onChange={(event: SyntheticEvent, val) => {
                            setSelectedPlans(val);
                        }}
                        multiple={true}
                        getOptionLabel={(option) => getOptionLabel(option, 'description')}
                        queryParams={{
                            ...(workorderPlanIds
                                ? {
                                      id__not_in: workorderPlanIds.map((planId) => planId).join(','),
                                  }
                                : {}),
                            ...(assetModelId ? { asset_models__id: assetModelId } : {}),
                            ...(assetType ? { is_vehicle_plan: assetType === 1 } : {}),
                            ...(assetType ? { is_implement_plan: assetType === 2 } : {}),
                            ...(assetType ? { is_equipment_plan: assetType === 3 } : {}),
                        }}
                    />
                    <ApiSelect
                        label={'Serviços avulsos'}
                        endpoint={'/api/v0/catalog/services/'}
                        value={selectedServices}
                        onChange={(event: SyntheticEvent, val) => {
                            setSelectedServices(val);
                        }}
                        multiple={true}
                        getOptionLabel={(option) => getOptionLabel(option, 'description')}
                    />
                </Stack>
            </DialogContent>
            <DialogActions
                sx={{
                    py: 1.5,
                    px: 3,
                    display: 'flex',
                    justifyContent: 'end',
                    borderTop: '1px solid #E0E0E0',
                    bgcolor: '#F9F9F9',
                }}
            >
                <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                <Button
                    variant={'contained'}
                    children={'Salvar'}
                    type={'submit'}
                    disabled={isPending}
                    startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                />
            </DialogActions>
        </Dialog>
    );
};

export default AddServiceModal;
