export const ACTIONS = {
    VIEW: 'view',
    CREATE: 'add',
    UPDATE: 'change',
    DELETE: 'delete',
};

export type ActionLabels = 'view' | 'add' | 'change' | 'delete';

export type PermArray = string[];

export interface ApiPerms {
    is_staff: boolean;
    is_superuser: boolean;
    is_driver: boolean;
    is_active: boolean;
    permissions: PermArray;
}