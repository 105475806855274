import React, { useEffect, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CheckIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/TaskAlt';
import WorkorderIcon from '@mui/icons-material/ReceiptLong';
import SaveIcon from '@mui/icons-material/Save';

import api from 'src/services/api';
import getIdLabel from 'src/components/utils/getIdLabel';

import CrudSelect from 'src/components/crud/fields/CrudSelect';
import { TData } from 'src/components/crud/Crud.d';
import { concatFieldErrors } from 'src/components/crud/modals/CreateUpdateModal';
import Loader from 'src/components/Loader';
import Error from 'src/components/Error';

import TabInfo from 'src/pages/maintenances/Workorder/WorkorderModal/TabInfo';
import TabServices from 'src/pages/maintenances/Workorder/WorkorderModal/TabServices';
import { Task, Workorder } from 'src/pages/maintenances/Workorder/Workorder.d';
import { Status, STATUS, StatusCell } from 'src/pages/maintenances/Workorder/Status.d';
import { CATEGORIES } from 'src/pages/maintenances/Workorder/Category.d';

import { WorkorderModalProps } from './WorkorderModal.d';
import TabProducts from './TabProducts';

const defaultValues = {
    id: 0,
    asset: '',
    asset_type: '',
    enterprise: '',
    scheduled_date: '',
    scheduled_hodometer: '',
    scheduled_hourmeter: '',
    is_external: false,
    category: CATEGORIES.PREVENTIVE,
    responsible: '',
    observation: '',
    status: STATUS.PENDING,
    start_date: '',
    end_date: '',
};

const TABS = {
    INFO: 0,
    SERVICES: 1,
    PRODUCTS: 2,
};

const WorkorderModal = (props: WorkorderModalProps) => {
    const { open, onClose, workorder } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * TABS CONTROL
     */
    const [selectedTab, setSelectedTab] = useState<number>(TABS.INFO);

    /**
     * VALUES CONTROL
     */
    const [values, setValues] = useState<TData>(defaultValues);
    const [errors, setErrors] = useState({});

    const valuesControl = {
        values,
        setValues,
        errors,
        setErrors,
    };

    /**
     * DATA LOAD
     */
    const fetch = async () => {
        return await api
            .get(`/api/v0/maintenances/workorders/${workorder.id}/`)
            .then((response) => response.data.results[0]);
    };

    const { data, isLoading, refetch } = useQuery({
        enabled: open && !!workorder,
        queryKey: ['workorders', workorder.id],
        queryFn: fetch,
    });

    useEffect(() => {
        if (data) {
            setValues({
                ...(Object.fromEntries(
                    Object.entries(data).map(([key, value]) => [key, value === null ? '' : value])
                ) as TData),
                asset_type: data.asset?.asset_type,
            });
        }
    }, [data]);

    const [doneCount, totalCount] = useMemo(
        () => [values?.tasks?.filter((task: Task) => task.is_done).length, values?.tasks?.length],
        [values.tasks]
    );

    /**
     * VALUES SUBMIT
     */
    const submit = async (status?: Status) => {
        const data: Record<string, any> = {
            ...values,
        };

        delete data.asset_type;

        // convert tasks to api format
        data.tasks = values.tasks.map((task: Task) => ({
            id: task.id,
            start_date: task.start_date || null,
            end_date: task.end_date || null,
            observation: task.observation,
            is_done: task.is_done,
            responsible: task?.responsible?.id ?? null,
        }));

        // convert nested objects to id
        ['asset', 'enterprise', 'responsible'].forEach((key) => {
            if (key in data) {
                data[key] = !data[key] || data[key].id === 0 ? null : data[key].id;
            }
        });

        // clear empty fields
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });

        // set status if provided
        if (status) {
            data.status = status;
        }

        return await api
            .put(`/api/v0/maintenances/workorders/${workorder.id}/`, data)
            .then((response) => response.data);
    };

    const {
        mutate: handleSave,
        isPending,
        isError,
    } = useMutation({
        mutationFn: submit,
        throwOnError: false,
        onError: (error: AxiosError<any>) => {
            try {
                let detail = null;
                let errors = error.response?.data as Record<string, string[]>;

                if (error.response?.data.hasOwnProperty('detail')) {
                    detail = errors.detail;
                    delete errors.detail;
                }

                let fieldErrors = concatFieldErrors(errors);

                setErrors(fieldErrors);

                if (detail) {
                    enqueueSnackbar(detail, { variant: 'error' });
                }
            } catch (e) {
                enqueueSnackbar('Erro ao salvar!', { variant: 'error' });
            }
        },
        onSuccess: () => {
            enqueueSnackbar('Ordem de serviço salva com sucesso.', { variant: 'success' });
            setErrors({});
            onClose();
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={open} maxWidth={'lg'} fullWidth>
            <DialogContent sx={{ height: '90vh' }}>
                <Stack direction={'column'} pb={3} height={'100%'}>
                    <Stack direction={'row'} justifyContent={'space-between'} pb={2.5}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <WorkorderIcon />
                            <Typography
                                variant={'h6'}
                                fontWeight={500}
                                children={`Ordem de serviço ${getIdLabel(workorder.id)}`}
                            />
                        </Stack>

                        <Stack spacing={1.5} direction={'row'} alignItems={'center'}>
                            {!!totalCount && (
                                <Tooltip title={'Serviços realizados'}>
                                    <Button
                                        startIcon={
                                            <DoneIcon
                                                fontSize={'small'}
                                                color={doneCount === totalCount ? 'success' : 'warning'}
                                            />
                                        }
                                        disabled={isLoading}
                                        onClick={() => setSelectedTab(TABS.SERVICES)}
                                        children={`${doneCount} / ${totalCount}`}
                                    />
                                </Tooltip>
                            )}
                            <Box>
                                <IconButton onClick={onClose} children={<CloseIcon />} disabled={isPending} />
                            </Box>
                        </Stack>
                    </Stack>

                    <Box borderBottom={1} borderColor={'divider'}>
                        <Tabs value={selectedTab} onChange={(e, t) => setSelectedTab(t)}>
                            <Tab label={'Dados'} id={TABS.INFO.toString()} disabled={isLoading} />
                            <Tab label={'Serviços'} id={TABS.SERVICES.toString()} disabled={isLoading} />
                            <Tab label={'Produtos'} id={TABS.PRODUCTS.toString()} disabled={isLoading} />
                        </Tabs>
                    </Box>

                    {isError ? (
                        <Error title={'Erro ao carregar a ordem de serviço'} showBackButton={false} />
                    ) : isLoading ? (
                        <Loader text={'Carregando ordem de serviço...'} />
                    ) : (
                        <>
                            {selectedTab === TABS.INFO && <TabInfo valuesControl={valuesControl} />}
                            {selectedTab === TABS.SERVICES && (
                                <TabServices values={values as Workorder} setValues={setValues} refetch={refetch} />
                            )}
                            {selectedTab === TABS.PRODUCTS && (
                                <TabProducts values={values as Workorder} refetch={refetch} />
                            )}
                        </>
                    )}
                </Stack>
            </DialogContent>

            <DialogActions
                sx={{
                    py: 1.5,
                    px: 3,
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderTop: '1px solid #E0E0E0',
                    bgcolor: '#F9F9F9',
                }}
            >
                <Box width={150}>
                    <CrudSelect
                        {...valuesControl}
                        column={{
                            accessorKey: 'status',
                            header: 'Status',
                            field: {
                                size: 'small',
                                type: 'select',
                                options: Object.fromEntries(
                                    Object.entries(STATUS).map(([key, value]) => [value, <StatusCell status={value} />])
                                ),
                            },
                        }}
                    />
                </Box>
                <Stack direction={'row'} spacing={1} p={1}>
                    <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                    <Button
                        variant={'contained'}
                        children={'Salvar'}
                        onClick={() => handleSave(undefined)}
                        disabled={isPending}
                        startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                    />
                    {doneCount === totalCount && totalCount > 0 && values.status !== STATUS.DONE && (
                        <Button
                            color={'success'}
                            variant={'contained'}
                            children={'Salvar e finalizar'}
                            onClick={() => handleSave(STATUS.DONE)}
                            disabled={isPending}
                            startIcon={isPending ? <CircularProgress size={16} /> : <CheckIcon />}
                        />
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default WorkorderModal;
