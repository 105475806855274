import React from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AppRoutes from '../../routes/appRoutes';

import Breadcrumb from '../../components/Breadcrumb';

const SettingsPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Configurações']} />
                </Box>
            </Stack>

            <Divider
                sx={{
                    mb: 5,
                    bgcolor: 'secondary.light',
                }}
            />

            <Stack>
                <Typography variant={'h6'} children={'Cadastros Frota'} />
                <Typography component={Link} href={AppRoutes.CabinTypes.path} children={'Tipo de Cabine'} />
                <Typography component={Link} href={AppRoutes.TrailerTypes.path} children={'Tipo de Carroceria'} />
                <Typography component={Link} href={AppRoutes.AxleTypes.path} children={'Tipo de Rodado'} />
                <Typography component={Link} href={AppRoutes.VehicleTypes.path} children={'Tipo de Veículo'} />
                <Typography component={Link} href={AppRoutes.AssetModels.path} children={'Modelos'} />
                <Typography component={Link} href={AppRoutes.AssetSubgroup.path} children={'Subgrupo'} />
                <Typography component={Link} href={AppRoutes.AssetGroup.path} children={'Grupo'} />
                <br />
                <Typography variant={'h6'} children={'Cadastros Catálogo'} />
                <Typography component={Link} href={AppRoutes.MeasurementUnits.path} children={'Unidades de Medida'} />
                <br />
                <Typography variant={'h6'} children={'Cadastros Produtos'} />
                <Typography component={Link} href={AppRoutes.ProductGroup.path} children={'Grupo de Produto'} />
                <Typography component={Link} href={AppRoutes.ProductSubgroup.path} children={'Subgrupo de Produto'} />
                <br />
                <Typography variant={'h6'} children={'Cadastros Serviços'} />
                <Typography component={Link} href={AppRoutes.ServiceGroups.path} children={'Grupo de Serviço'} />
                <Typography component={Link} href={AppRoutes.ServiceSubgroups.path} children={'Subgrupo de Serviço'} />
            </Stack>
        </>
    );
};

export default SettingsPage;
