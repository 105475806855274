import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import { TData } from 'src/components/crud/Crud.d';

import { CrudHeaderProps } from './CrudHeader.d';

const CrudHeader = <T extends TData>(props: CrudHeaderProps<T>) => {
    const {
        // STRUCTURE
        name,

        // ACTIONS
        extraCrudActions,
        handleCreateUpdate,

        // PERMISSIONS
        enableCreate,
    } = props;

    return (
        <Stack justifyContent={'space-between'} direction={'row'} flexWrap={'wrap'} gap={1} mb={2}>
            <Typography mb={0} variant={'h4'} children={name.plural} />
            <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                {extraCrudActions ? extraCrudActions() : null}
                {enableCreate && (
                    <Button onClick={() => handleCreateUpdate(null)} startIcon={<AddIcon />} children={name.singular} />
                )}
            </Stack>
        </Stack>
    );
};

export default CrudHeader;
