import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import ClassIcon from '@mui/icons-material/Bookmarks';
import CloseIcon from '@mui/icons-material/Clear';
import DocsIcon from '@mui/icons-material/Description';
import EquipmentIcon from '@mui/icons-material/Microwave';
import InfoIcon from '@mui/icons-material/Assignment';
import SaveIcon from '@mui/icons-material/Save';

import api from 'src/services/api';

import CrudApiSelect from 'src/components/crud/fields/CrudApiSelect';
import CrudDatePicker from 'src/components/crud/fields/CrudDatePicker';
import CrudSelect from 'src/components/crud/fields/CrudSelect';
import CrudText from 'src/components/crud/fields/CrudText';

import { columns as modelColumns, endpoint as modelEndpoint, name as modelName } from 'src/pages/fleet/AssetModel';

import { TData } from 'src/components/crud/Crud.d';
import ModalSection from 'src/components/ModalSection';
import CreateUpdateModal, { concatFieldErrors } from 'src/components/crud/modals/CreateUpdateModal';
import { ASSET_TYPES, PROPERTY_TYPES, PROPERTY_TYPES_LABELS_MAP } from 'src/pages/fleet/Fleet.d';

import { EquipmentModalProps } from './EquipmentModal.d';

const defaultValues = {
    id: 0,
    equipment_brand: {
        id: 0,
        description: '',
    },
    asset_model: '',
    serial_id: '',
    motor_id: '',
    group: {
        id: 0,
        description: '',
    },
    subgroup: {
        id: 0,
        description: '',
    },
    property: '',
    owner: {
        id: 0,
        name: '',
    },
    invoice_id: '',
    initial_hour_counter: '',
    purchase_date: '',
    purchase_value: '',
    purchase_depreciation: '',
    warranty_date: '',
    maintenance_plan_start: '',
    maintenance_plan_end: '',
    is_active: true,
};

const EquipmentModal = (props: EquipmentModalProps) => {
    const { open, onClose, equipment } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * VALUES CONTROL
     */
    const [values, setValues] = useState<TData>(defaultValues);
    const [errors, setErrors] = useState({});

    const valuesControl = {
        values,
        setValues,
        errors,
        setErrors,
    };

    /**
     * DATA LOAD
     */
    useEffect(() => {
        if (open) {
            if (equipment) {
                setValues({
                    ...Object.fromEntries(
                        Object.entries(equipment).map(([key, value]) => [key, value === null ? '' : value])
                    ),
                    id: equipment.id,
                    equipment_brand: equipment.asset_model?.brand ?? '',
                    group: equipment.subgroup?.group ?? '',
                    owner: equipment.owner ?? '',
                } as TData);
            } else {
                setValues(defaultValues);
            }
        }
    }, [equipment, open]);

    /**
     * VALUES SUBMIT
     */
    const submitEquipment = async () => {
        const data: Record<string, any> = {
            ...values,
        };

        // convert nested objects to id
        ['subgroup', 'asset_model', 'owner'].forEach((key) => {
            if (key in data) {
                data[key] = !data[key] || data[key].id === 0 ? null : data[key].id;
            }
        });

        delete data.group;
        delete data.equipment_brand;

        // clear empty fields
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });

        if (equipment) {
            return await api.put(`/api/v0/fleet/equipments/${equipment.id}/`, data).then((response) => response.data);
        }

        return await api.post('/api/v0/fleet/equipments/', data).then((response) => response.data);
    };

    const { mutate: handleSave, isPending } = useMutation({
        mutationFn: submitEquipment,
        throwOnError: false,
        onError: (error: AxiosError<any>) => {
            try {
                let detail = null;
                let errors = error.response?.data as Record<string, string[]>;

                if (error.response?.data.hasOwnProperty('detail')) {
                    detail = errors.detail;
                    delete errors.detail;
                }

                let fieldErrors = concatFieldErrors(errors);

                setErrors(fieldErrors);

                if (detail) {
                    enqueueSnackbar(detail, { variant: 'error' });
                }
            } catch (e) {
                enqueueSnackbar('Erro ao salvar!', { variant: 'error' });
            }
        },
        onSuccess: () => {
            enqueueSnackbar('Equipamento salvo com sucesso.', { variant: 'success' });
            setErrors({});
            onClose();
        },
    });

    /**
     * CREATE DIALOGS
     */
    const [modelOpen, setModelOpen] = useState(false);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Dialog open={open} maxWidth={'lg'} fullWidth>
                <DialogContent sx={{ height: '90vh' }}>
                    <Stack direction={'column'} sx={{ pb: 3 }}>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            sx={{
                                pb: 2.5,
                            }}
                        >
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <EquipmentIcon />
                                <Typography
                                    variant={'h6'}
                                    fontWeight={500}
                                    children={equipment ? `Equipamento ${equipment.serial_id} ` : 'Novo Equipamento'}
                                />
                            </Stack>

                            <Box>
                                <IconButton onClick={onClose} children={<CloseIcon />} disabled={isPending} />
                            </Box>
                        </Stack>

                        <ModalSection title={'Identificação do Equipamento'} icon={InfoIcon} defaultExpanded>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'equipment_brand.name',
                                            header: 'Marca',
                                            field: {
                                                type: 'search',
                                                name: 'equipment_brand',
                                                endpoint: '/api/v0/registry/brands/',
                                                labelKey: (brand) => (brand ? brand.description : ''),
                                                queryParams: {
                                                    is_equipment_brand: true,
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        readOnly={!values.equipment_brand?.id}
                                        column={{
                                            accessorKey: 'asset_model.name',
                                            header: 'Modelo',
                                            field: {
                                                type: 'search',
                                                name: 'asset_model',
                                                endpoint: '/api/v0/fleet/models/',
                                                labelKey: (model) => (model ? model.description : ''),
                                                queryParams: {
                                                    brand__id: values.equipment_brand?.id,
                                                    asset_type: ASSET_TYPES.EQUIPMENT,
                                                },
                                                inputAction: {
                                                    label: <AddIcon />,
                                                    onClick: () => setModelOpen(true),
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'motor_id',
                                            header: 'Nº do Motor',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'serial_id',
                                            header: 'Nº de Série',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </ModalSection>

                        <ModalSection title={'Documentação'} icon={DocsIcon} defaultExpanded>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'invoice_id',
                                            header: 'Nota Fiscal',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'initial_hour_counter',
                                            header: 'Horímetro Inicial',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudDatePicker
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'purchase_date',
                                            header: 'Data da Aquisição',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'purchase_value',
                                            header: 'Valor da Aquisição',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'purchase_depreciation',
                                            header: 'Depreciação',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudDatePicker
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'warranty_date',
                                            header: 'Garantia',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudDatePicker
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'maintenance_plan_start',
                                            header: 'Início Plano Manutenção',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudDatePicker
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'maintenance_plan_end',
                                            header: 'Fim Plano Manutenção',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </ModalSection>

                        <ModalSection title={'Classificação'} icon={ClassIcon}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'group.description',
                                            header: 'Grupo Operacional',
                                            field: {
                                                type: 'search',
                                                name: 'group',
                                                endpoint: '/api/v0/fleet/groups/',
                                                labelKey: (e) => (e ? e.description : ''),
                                                queryParams: {
                                                    asset_type: ASSET_TYPES.EQUIPMENT,
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        readOnly={!values.group?.id}
                                        column={{
                                            accessorKey: 'subgroup.description',
                                            header: 'Subgrupo',
                                            field: {
                                                type: 'search',
                                                name: 'subgroup',
                                                endpoint: '/api/v0/fleet/subgroups/',
                                                labelKey: (e) => (e ? e.description : ''),
                                                queryParams: {
                                                    group__id: values.group?.id,
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudSelect
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'property',
                                            header: 'Propriedade',
                                            field: {
                                                type: 'select',
                                                options: PROPERTY_TYPES_LABELS_MAP,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {values.property === PROPERTY_TYPES.THIRD_PARTY && (
                                        <CrudApiSelect
                                            {...valuesControl}
                                            column={{
                                                accessorKey: 'owner.name',
                                                header: 'Proprietário',
                                                field: {
                                                    type: 'search',
                                                    name: 'owner',
                                                    endpoint: '/api/v0/registry/entities/',
                                                    labelKey: (e) => (e ? e.name : ''),
                                                },
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </ModalSection>
                    </Stack>
                </DialogContent>

                <DialogActions
                    sx={{
                        py: 1.5,
                        px: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderTop: '1px solid #E0E0E0',
                        bgcolor: '#F9F9F9',
                    }}
                >
                    <Box width={150}>
                        <CrudSelect
                            {...valuesControl}
                            column={{
                                accessorKey: 'is_active',
                                header: 'Status',
                                field: {
                                    size: 'small',
                                    type: 'select',
                                    options: {
                                        true: 'Ativo',
                                        false: 'Inativo',
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Stack direction={'row'} spacing={1} sx={{ p: 1 }}>
                        <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                        <Button
                            variant={'contained'}
                            children={'Salvar'}
                            onClick={() => handleSave()}
                            disabled={isPending}
                            startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                        />
                    </Stack>
                </DialogActions>
            </Dialog>

            {/* CREATE MODEL MODAL */}
            <CreateUpdateModal
                columns={modelColumns.map((c) => ({
                    enableEditing: true,
                    ...c,
                }))}
                open={modelOpen}
                setOpen={setModelOpen}
                endpoint={modelEndpoint}
                name={modelName}
            />
        </>
    );
};

export default EquipmentModal;
