import React, { Fragment } from 'react';

import CrudApiSelect, { CrudApiSelectProps } from 'src/components/crud/fields/CrudApiSelect';
import CrudCheckBox, { CrudCheckBoxProps } from 'src/components/crud/fields/CrudCheckBox';
import CrudDatePicker, { CrudDatePickerProps } from 'src/components/crud/fields/CrudDatePicker';
import CrudDatetimePicker, { CrudDatetimePickerProps } from 'src/components/crud/fields/CrudDatetimePicker';
import CrudHeader, { CrudHeaderProps } from 'src/components/crud/fields/CrudHeader';
import CrudMaskText, { CrudMaskTextProps } from 'src/components/crud/fields/CrudMaskText';
import CrudPassword, { CrudPasswordProps } from 'src/components/crud/fields/CrudPassword';
import CrudSelect, { CrudSelectProps } from 'src/components/crud/fields/CrudSelect';
import CrudText, { CrudTextProps } from 'src/components/crud/fields/CrudText';

import getIsReadOnly from 'src/components/utils/getIsReadOnly';

import { CrudFormProps } from 'src/components/crud/CrudForm';
import { TData } from 'src/components/crud/Crud.d';

const CrudForm = <T extends TData>(props: CrudFormProps<T>) => {
    const { columns, values, setValues, errors, readOnly = false, size } = props;

    const commonFieldProps = {
        values: values,
        setValues: setValues,
        errors: errors,
        size: size,
    };

    return (
        <Fragment>
            {columns.map((column) => {
                const inputProps = {
                    key: column['accessorKey'],
                    column: column,
                    readOnly: readOnly || getIsReadOnly(column.readOnly, values),
                    ...commonFieldProps,
                };

                if (!column.enableEditing) {
                    return null;
                }

                switch (column.field?.type) {
                    case 'header':
                        return <CrudHeader {...(inputProps as CrudHeaderProps<T>)} />;
                    case 'search':
                        return <CrudApiSelect {...(inputProps as CrudApiSelectProps<T>)} />;
                    case 'date':
                        return <CrudDatePicker {...(inputProps as CrudDatePickerProps<T>)} />;
                    case 'datetime':
                        return <CrudDatetimePicker {...(inputProps as CrudDatetimePickerProps<T>)} />;
                    case 'select':
                        return <CrudSelect {...(inputProps as CrudSelectProps<T>)} />;
                    case 'check':
                        return <CrudCheckBox {...(inputProps as CrudCheckBoxProps<T>)} />;
                    case 'password':
                        return <CrudPassword {...(inputProps as CrudPasswordProps<T>)} />;
                    case 'mask-text':
                        return <CrudMaskText {...(inputProps as CrudMaskTextProps<T>)} />;
                    case 'custom':
                        return column.field.component(values, setValues, errors, readOnly);
                    default:
                        return <CrudText {...(inputProps as CrudTextProps<T>)} />;
                }
            })}
        </Fragment>
    );
};

export default CrudForm;
