import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import LogoutIcon from '@mui/icons-material/LogoutRounded';
import PersonIcon from '@mui/icons-material/PersonRounded';

import AppRoutes from '../../routes/appRoutes';
import {removeToken} from '../../services/auth';
import {removeUserData} from '../../services/storage/user/user';

import ProfileImage from '../../assets/img/user/avatar.png';

function NavBar() {

    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = useState<HTMLElement | null>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogoutClick = () => {
        handleCloseUserMenu();
        removeToken();
        removeUserData();
        window.location.reload();
    };

    const handleProfileClick = () => {
        handleCloseUserMenu();
        navigate(AppRoutes.UserSettings.path);
    };

    return (<AppBar
        position={'static'}
        elevation={0}
        color={'transparent'}
        sx={{pt: 1}}
    >
        <Container maxWidth={'xl'}>
            <Toolbar
                disableGutters
                sx={{justifyContent: 'end'}}
            >
                <Box sx={{flexGrow: 0}}>
                    <IconButton
                        onClick={handleOpenUserMenu}
                        sx={{p: 0}}
                    >
                        <Avatar src={ProfileImage}/>
                    </IconButton>
                    <Menu
                        id={'menu-appbar'}
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                        sx={{mt: '45px'}}
                    >
                        <MenuItem
                            onClick={handleProfileClick}
                            sx={{py: 1}}
                        >
                            <PersonIcon
                                fontSize={'small'}
                                color={'primary'}
                                sx={{mr: 1}}
                            />
                            <Typography sx={{mr: 3}}>
                                Meu Perfil
                            </Typography>
                        </MenuItem>
                        <MenuItem
                            onClick={handleLogoutClick}
                            sx={{py: 1}}
                        >
                            <LogoutIcon
                                fontSize={'small'}
                                color={'error'}
                                sx={{mr: 1}}
                            />
                            <Typography sx={{mr: 3}}>
                                Sair
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </Container>
    </AppBar>);
}

export default NavBar;
