import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';

import { VehicleType } from 'src/pages/fleet/VehicleType/VehicleType.d';
import { Columns } from 'src/components/crud/Crud.d';

const name = {
    singular: 'Tipo de Veículo',
    plural: 'Tipos de Veículos',
};

const columns: Columns<VehicleType> = [
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
];

const VehicleTypesPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Configurações', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.VehicleTypes}
                endpoint={'/api/v0/fleet/vehicle-types/'}
                columns={columns}
            />
        </>
    );
};

export default VehicleTypesPage;
