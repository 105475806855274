import React, { useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { Supply } from 'src/pages/maintenances/Workorder/Workorder.d';
import { TabProductsProps } from './TabProducts.d';
import ProductModal from './ProductModal';
import DeleteModal from 'src/components/crud/modals/DeleteModal';

const columns = [
    {
        header: 'Produto',
        accessorKey: 'product.description',
        enableEditing: true,
    },
    {
        header: 'Quantidade',
        accessorKey: 'quantity',
        enableEditing: true,
    },
    {
        header: 'Preço',
        accessorKey: 'avg_unit_cost',
        enableEditing: true,
    },
];

const name = {
    singular: 'Produto',
    plural: 'Produtos',
};

const TabProducts = (props: TabProductsProps) => {
    const { values, refetch } = props;

    /**
     * CREATE/UPDATE MODAL
     */
    const [createUpdateEntity, setCreateUpdateEntity] = useState<Supply | null>(null);
    const [createUpdateOpen, setCreateUpdateOpen] = useState(false);

    const openCreateUpdateModal = (entity: Supply | null) => {
        setCreateUpdateEntity(entity);
        setCreateUpdateOpen(true);
    };

    /**
     * DELETE MODAL
     */
    const [deleteEntity, setDeleteEntity] = useState<Supply | null>(null);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const openDeleteModal = (entity: Supply) => {
        setDeleteEntity(entity);
        setDeleteOpen(true);
    };

    /**
     * TABLE DATA
     */
    const table = useMaterialReactTable({
        columns,
        data: values.supplies,
        enablePagination: false,
        enableBottomToolbar: false,
        enableTopToolbar: false,
        // STATES
        state: {
            density: 'compact',
        },
        initialState: {
            pagination: { pageIndex: 0, pageSize: 100 },
        },
        // ROW ACTIONS
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => {
            return (
                <>
                    <Tooltip title={'Editar item'}>
                        <IconButton
                            children={<EditIcon color={'inherit'} />}
                            onClick={() => openCreateUpdateModal(row.original)}
                        />
                    </Tooltip>
                    <Tooltip title={'Remover item'}>
                        <IconButton
                            children={<DeleteIcon color={'error'} />}
                            onClick={() => openDeleteModal(row.original)}
                        />
                    </Tooltip>
                </>
            );
        },
        muiTableBodyRowProps: ({ row }) => ({
            onDoubleClick: () => openCreateUpdateModal(row.original),
            sx: { cursor: 'pointer' },
        }),
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack spacing={2} my={2}>
                <Stack direction={'row'} justifyContent={'end'}>
                    <Button
                        startIcon={<AddIcon />}
                        children={'Add Produtos'}
                        variant={'outlined'}
                        onClick={() => setCreateUpdateOpen(true)}
                    />
                </Stack>

                <MaterialReactTable table={table} />
            </Stack>

            {/** CREATE/UPDATE MODAL */}
            <ProductModal
                open={createUpdateOpen}
                workorderId={values.id}
                modalEntity={createUpdateEntity}
                onClose={() => {
                    setCreateUpdateOpen(false);
                    setCreateUpdateEntity(null);
                }}
                onSave={() => {
                    setCreateUpdateOpen(false);
                    setCreateUpdateEntity(null);
                    refetch();
                }}
            />

            {/** DELETE CONFIRM */}
            <DeleteModal
                // STRUCTURE
                name={name}
                endpoint={`/api/v0/maintenances/workorders/${values.id}/supplies/`}
                // MODAL CONTROL
                modalEntity={deleteEntity}
                setOpen={setDeleteOpen}
                open={deleteOpen}
                // ACTIONS
                refresh={() => refetch()}
            />
        </>
    );
};

export default TabProducts;
