import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { brlFormatter } from 'src/services/formatters';

import AppRoutes from 'src/routes/appRoutes';
import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns } from 'src/components/crud/Crud.d';

import { Product } from './Product.d';
import ProductModal from './ProductModal';

export const name = {
    singular: 'Material',
    plural: 'Materiais',
};

export const columns: Columns<Product> = [
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
    {
        accessorKey: 'unit.description',
        header: 'Unidade',
        field: {
            type: 'search',
            name: 'unit',
            endpoint: '/api/v0/catalog/measurement-units/',
            labelKey: (e) => (e ? e.description : ''),
        },
    },
    {
        accessorKey: 'last_kardex__balance',
        header: 'Qtd. Estoque',
        Cell: ({ row }) => row.original?.last_kardex?.balance ?? 0,
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        accessorKey: 'last_kardex__unit_cost',
        header: 'Custo',
        Cell: ({ row }) => brlFormatter(row.original?.last_kardex?.unit_cost ?? 0),
        enableColumnFilter: false,
        enableSorting: false,
    },
];

export const endpoint = '/api/v0/catalog/products/';

const ProductsPage = () => {
    /**
     * MODAL CONTROL
     */
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEntity, setModalEntity] = useState<Product | null>(null);
    const [refresh, setRefresh] = useState(false);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Almoxarifado', 'Cadastros', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.Products}
                endpoint={endpoint}
                columns={columns}
                // Features
                controlledRefresh={refresh}
                setControlledRefresh={setRefresh}
                handleCreateUpdate={(entity: Product | null) => {
                    setModalOpen(true);
                    setModalEntity(entity);
                }}
            />

            <ProductModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setModalEntity(null);
                    setRefresh(!refresh);
                }}
                product={modalEntity}
            />
        </>
    );
};

export default ProductsPage;
