import React, { ChangeEvent } from 'react';

import TextField from '@mui/material/TextField';

import { TData } from 'src/components/crud/Crud.d';

import getNestedValue from 'src/components/utils/getNestedValue';
import setNestedValue from 'src/components/utils/setNestedValue';
import hasNestedValue from 'src/components/utils/hasNestedValue';

import { CrudTextProps } from './CrudText.d';

const CrudText = <T extends TData>(props: CrudTextProps<T>) => {
    const { column, values, setValues, errors, readOnly = false, required = false, size = 'medium' } = props;

    const { accessorKey, header, field } = column;

    return (
        <TextField
            fullWidth
            autoComplete={'new-password'} // Forced disable of browser autocomplete
            type={field?.type ?? 'text'}
            size={size}
            label={header}
            multiline={column.field && 'multiline' in column.field && (column.field?.multiline ?? false)}
            disabled={readOnly}
            required={required}
            value={getNestedValue(values, accessorKey, '')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setValues(setNestedValue(values, accessorKey, e.target.value));
            }}
            error={hasNestedValue(errors, accessorKey)}
            helperText={getNestedValue(errors, accessorKey, '')}
            InputProps={column.field?.InputProps && column.field.InputProps}
            inputProps={column.field?.inputProps && column.field?.inputProps}
        />
    );
};

export default CrudText;
