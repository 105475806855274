import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { AxiosResponse } from 'axios';

import api from 'src/services/api';
import CrudModal from 'src/components/crud/modals/CrudModal';
import { TData } from 'src/components/crud/Crud.d';

import { CreateUpdateModalProps } from './CreateUpdateModal.d';
import { concatFieldErrors, getFirstFieldError } from './services';

const CreateUpdateModal = <T extends TData>(props: CreateUpdateModalProps<T>) => {
    const {
        // STRUCTURE
        name,
        endpoint,
        columns,

        // MODAL CONTROL
        open,
        setOpen,

        // ENTITY CONTROL
        entity,
        setEntity,

        // ACTIONS
        refetch,
        formatPayload,
        getIsReadOnly,
        extraActions,

        // EVENTS
        afterSubmit,

        // VISUAL
        size,
    } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * CREATE/UPDATE MODAL
     */
    const [errors, setErrors] = useState<Record<string, any>>({});

    // Remove errors from modal when the entity changes
    useEffect(() => {
        setErrors({});
    }, [entity]);

    const handleSubmitError = (error: any) => {
        try {
            let detail = null;
            let errors = error.response.data as Record<string, string[]>;

            if (error.response.data.hasOwnProperty('detail')) {
                detail = errors.detail;
                delete errors.detail;
            }

            let fieldErrors = concatFieldErrors(errors);

            setErrors(fieldErrors);

            if (detail) {
                enqueueSnackbar(detail, { variant: 'error' });
            } else {
                enqueueSnackbar(getFirstFieldError(fieldErrors), { variant: 'error' });
            }
        } catch (e) {
            enqueueSnackbar('Erro ao cadastrar!', { variant: 'error' });
        }
    };

    const handleSubmitSuccess = (res: AxiosResponse<any, any>) => {
        if (setOpen) {
            setOpen(false);
        }

        if (setEntity) {
            setEntity(null);
        }

        if (afterSubmit) {
            afterSubmit(res);
        }

        if (refetch) {
            refetch();
        }

        setErrors({});
    };

    const handleCreateUpdateSubmit = async (formData: FormData, entity_id: number, values: T) => {
        let data: any = formData;

        if (formatPayload) {
            data = formatPayload(formData, entity_id, values);
        }

        if (entity_id) {
            return await api.put(`${endpoint}${entity_id}/`, data).then(handleSubmitSuccess).catch(handleSubmitError);
        } else {
            return await api.post(`${endpoint}`, data).then(handleSubmitSuccess).catch(handleSubmitError);
        }
    };

    const handleCreateUpdateCancel = () => {
        if (setOpen) {
            setOpen(false);
        }

        if (setEntity) {
            setEntity(null);
        }
    };

    return (
        <CrudModal
            // States
            open={open}
            entity={entity}
            errors={errors}
            readOnly={entity && getIsReadOnly ? getIsReadOnly(entity) : false}
            // Structure
            columns={columns.map((c) => (c.enableEditing === undefined ? { ...c, enableEditing: true } : c))}
            // Texts
            readTitle={`Visualizar ${name.singular}`}
            createTitle={`Criar ${name.singular}`}
            updateTitle={`Editar ${name.singular}`}
            // Events
            onSubmit={handleCreateUpdateSubmit}
            onCancel={handleCreateUpdateCancel}
            // Actions
            extraActions={extraActions}
            // Visual
            size={size}
        />
    );
};

export default CreateUpdateModal;
