import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';
import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns } from 'src/components/crud/Crud.d';

import BundleModal from './BundleModal';
import { Bundle } from './Bundle.d';

export const name = {
    singular: 'Conjunto',
    plural: 'Conjuntos',
};

export const columns: Columns<Bundle> = [
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
];

export const endpoint = '/api/v0/catalog/bundles/';

const BundlesPage = () => {
    /**
     * MODAL CONTROL
     */
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEntity, setModalEntity] = useState<Bundle | null>(null);
    const [refresh, setRefresh] = useState(false);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Configurações', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.Bundles}
                endpoint={endpoint}
                columns={columns}
                // Features
                controlledRefresh={refresh}
                setControlledRefresh={setRefresh}
                handleCreateUpdate={(entity: Bundle | null) => {
                    setModalOpen(true);
                    setModalEntity(entity);
                }}
            />

            <BundleModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setModalEntity(null);
                    setRefresh(!refresh);
                }}
                bundle={modalEntity}
            />
        </>
    );
};

export default BundlesPage;
