/**
 * STORE USER PERMISSIONS INFORMATION ON sessionStorage
 */

import api from '../../api';
import { getPermissionLabel, Route } from '../../../routes/appRoutes';
import { ACTIONS, ApiPerms, PermArray } from '../permissions';

export const PERMISSIONS_KEY = '@cargofix-permissions';
export const SUPERUSER_KEY = '@cargofix-superuser';

/**
 * Set user permissions data in sessionStorage
 */
export const setPermissionsData = (permissionsData: ApiPerms) => {
    sessionStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissionsData.permissions));
    sessionStorage.setItem(SUPERUSER_KEY, JSON.stringify(permissionsData.is_superuser));
};

export const removePermissionsData = () => {
    sessionStorage.removeItem(PERMISSIONS_KEY);
    sessionStorage.removeItem(SUPERUSER_KEY);
};

/**
 * Get permissions data from sessionStorage
 */
export const getCachedPermissions = () => {
    const sessionPermissions = sessionStorage.getItem(PERMISSIONS_KEY);
    return sessionPermissions ? JSON.parse(sessionPermissions) : null;
};

export const getCachedSuperUser = () => {
    const sessionSuperuser = sessionStorage.getItem(SUPERUSER_KEY);
    return sessionSuperuser ? JSON.parse(sessionSuperuser) : null;
};

/**
 * Get permissions data from sessionStorage or API
 */
export const getPermissions = async () => {
    let permissionsData = getCachedPermissions();

    if (permissionsData == null) {
        permissionsData = (await fetchPermissionsData()).permissions;
    }

    return permissionsData;
};

/**
 * Check if the user has the permission
 */
export const checkPermission = (permissions: PermArray, permission: string) => {
    if (!permissions) {
        return false;
    }

    return permissions.includes(permission);
};

export const checkRoutePermission = (permissions: PermArray, route: Route, action: string) => {
    if (route.allowAny) {
        return true;
    }

    const permLabel = getPermissionLabel(route, action);

    if (!permLabel) {
        return false;
    }

    return checkPermission(permissions, permLabel);
};

export const canAccessRoute = (permissions: PermArray, superuser: boolean, route: Route) => {
    const accessAction = route.accessAction ?? ACTIONS.VIEW;
    return (route.superuser && superuser) || checkRoutePermission(permissions, route, accessAction);
};

/**
 * Verify if the user has the given permission
 */
export const hasPermission = (permission: string) => {
    return checkPermission(getCachedPermissions(), permission);
};

/**
 * Given a route and action, verify if the user has the permission to access it
 */
export const hasRoutePermission = (route: Route, action: string) => {
    const permLabel = getPermissionLabel(route, action);

    if (!permLabel) {
        return false;
    }

    return hasPermission(permLabel);
};

/**
 * Return the four CRUD permissions for the given route
 */
export const getRoutePermissions = (route: Route) => {
    return {
        canCreate: hasRoutePermission(route, ACTIONS.CREATE),
        canView: hasRoutePermission(route, ACTIONS.VIEW),
        canUpdate: hasRoutePermission(route, ACTIONS.UPDATE),
        canDelete: hasRoutePermission(route, ACTIONS.DELETE),
    };
};

/**
 * Fetch permissions data from the API
 */
export const fetchPermissionsData = async () => {
    return await api
        .get('/api/v0/users/permissions/my/')
        .then((res) => {
            setPermissionsData(res.data);
            return res.data;
        })
        .catch((err) => {
            removePermissionsData();
        });
};
