export const concatFieldErrors = (fieldErrors: Record<string, any>) => {
    let errors = {} as Record<string, any>;

    for (let [key, val] of Object.entries(fieldErrors)) {
        if (Array.isArray(val)) {
            errors[key] = val.join(',');
        } else if (typeof val === 'object') {
            errors[key] = concatFieldErrors(val);
        }
    }

    return errors;
};

export const getFirstFieldError = (fieldErrors: Record<string, any>): string => {
    const firstError = Object.values(fieldErrors)[0];

    if (typeof firstError === 'string') {
        return firstError;
    }

    return getFirstFieldError(fieldErrors[0]);
};
