import React, { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IconMenuItem, NestedMenuItem } from 'mui-nested-menu';

import {
    MaterialReactTable,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_Row,
    MRT_SortingState,
} from 'material-react-table';

import dayjs from 'dayjs';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';

import api from 'src/services/api';
import encodeFieldId from 'src/components/utils/encodeFieldId';

import { FilterLookup, RightClickMenuItem, TData } from 'src/components/crud/Crud.d';
import CrudTableBtn from 'src/components/crud/CrudTableBtn';

import { CrudTableProps, CrudTableWithQueryProps } from './CrudTable.d';
import { getLookupFilterField, rmLookups } from './services';

const CrudTable = <T extends TData>(props: CrudTableProps<T>) => {
    const {
        // STRUCTURE
        columns,
        endpoint,

        // ACTIONS
        refresh,
        rowActions,
        extraRowActions,
        handleCreateUpdate,
        rowDoubleClick,
        getIsReadOnly,
        formatData,
        rightClickMenuItems,

        // EVENTS
        onLoad,

        // PERMISSIONS
        enableUpdate = true,

        // FILTERS
        extraFilters,

        // INITIAL PARAMS
        initialGlobalFilter,
        initialSorting,
        initialPagination,
        initialColumnFilters,
    } = props;

    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    /**
     * TABLE CONTROL
     */
    const [globalFilter, setGlobalFilter] = useState<string>(initialGlobalFilter);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(rmLookups(initialColumnFilters));
    const [sorting, setSorting] = useState<MRT_SortingState>(initialSorting);
    const [pagination, setPagination] = useState<MRT_PaginationState>(initialPagination);

    /**
     * API QUERY
     */
    useEffect(() => {
        const params = {} as Record<string, string>;

        columnFilters.forEach((filter) => {
            let filterValue = filter.value;
            let filterLookup: FilterLookup = 'icontains';

            // If the filter on the displayed columns
            if (columns.map((c) => c.accessorKey).includes(filter.id)) {
                const column = columns.filter((c) => c.accessorKey === filter.id)[0];

                // Format the field based on filter variant
                switch (column.filterVariant) {
                    case 'date':
                        if (dayjs.isDayjs(filterValue)) {
                            filterValue = dayjs(filterValue).format('YYYY-MM-DD');
                        }
                        break;
                }

                if (column.filterLookup) {
                    filterLookup = column.filterLookup;
                }
            }

            const filterField = getLookupFilterField(encodeFieldId(filter.id), filterLookup);
            params[filterField] = filterValue as string;
        });

        extraFilters?.forEach((filter) => {
            params[encodeFieldId(filter.id)] = filter.value as string;
        });

        if (globalFilter) {
            params['search'] = globalFilter;
        }

        if (pagination) {
            params['limit'] = `${pagination.pageSize}`;
            params['offset'] = `${pagination.pageIndex * pagination.pageSize}`;
        }

        if (sorting.length > 0) {
            params['ordering'] = sorting.map((col) => (col.desc ? '-' : '') + encodeFieldId(col.id)).join(',');
        }

        SetURLSearchParams(params);
    }, [globalFilter, columnFilters, sorting, pagination, extraFilters]);

    const fetchData = async () => {
        return await api.get(endpoint, { params: URLSearchParams });
    };

    const fetchAndFormatData = async () => {
        return await fetchData().then((res) => {
            if (onLoad) {
                onLoad(res.data);
            }

            if (formatData) {
                return formatData(res.data);
            }

            return res.data;
        });
    };

    // API QUERY
    const { data, isError, isFetching, isLoading, refetch } = useQuery({
        queryKey: ['table-data'],
        queryFn: fetchAndFormatData,
    });

    // Refetch from outside
    useEffect(() => {
        refetch();
    }, [refresh, URLSearchParams]);

    /**
     * ROW ACTIONS
     */
    const renderRowActions = (row: MRT_Row<T>): ReactNode => {
        const entity = row.original;

        if (rowActions) {
            return rowActions(entity);
        }

        const readOnly = getIsReadOnly ? getIsReadOnly(entity) : false;

        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                }}
            >
                {enableUpdate && (
                    <CrudTableBtn
                        entity={entity}
                        onClick={handleCreateUpdate}
                        tooltip={readOnly || !enableUpdate ? 'Visualizar' : 'Editar'}
                        icon={readOnly || !enableUpdate ? VisibilityIcon : EditIcon}
                    />
                )}

                {extraRowActions &&
                    extraRowActions.map((action, i) => {
                        // if ('show' in action && !action.show) {
                        //     return <></>;
                        // }

                        if (action.buildLink) {
                            action.link = action.buildLink(entity);
                        }

                        return <CrudTableBtn entity={entity} {...action} key={`action-${i}`} />;
                    })}
            </Box>
        );
    };

    /**
     * CELL ACTIONS MENU
     */
    const createMenuItems = (items: RightClickMenuItem<T>[], closeMenu: () => void, entity: T) => {
        return items.map((item, index) => {
            const { label, icon, childs, disabled, onClick } = item;

            if (childs) {
                return (
                    <NestedMenuItem
                        key={index}
                        label={label}
                        leftIcon={icon}
                        parentMenuOpen={true}
                        children={createMenuItems(childs, closeMenu, entity)}
                        disabled={disabled}
                        sx={{
                            minWidth: '250px',
                        }}
                    />
                );
            }

            return (
                <IconMenuItem
                    key={index}
                    label={label}
                    leftIcon={icon}
                    onClick={() => {
                        if (onClick) {
                            onClick(entity);
                        }
                        closeMenu();
                    }}
                    disabled={disabled}
                    sx={{
                        minWidth: '250px',
                    }}
                />
            );
        });
    };

    /**
     * COMPONENT RENDER
     */
    return (
        <>
            {/** TABLE */}
            <MaterialReactTable
                // Data
                columns={columns.filter((col) => !col?.hideColumn)}
                data={data?.results ?? []}
                rowCount={data?.count ?? 0}
                // Config
                localization={{
                    ...MRT_Localization_PT_BR,
                    ...{ actions: '' },
                }}
                autoResetPageIndex={false}
                enableDensityToggle={false}
                enableColumnResizing={true}
                enableHiding={false}
                enableEditing
                defaultColumn={{
                    enableResizing: true,
                    grow: true,
                }}
                // Row actions
                positionActionsColumn={'last'}
                renderRowActions={({ row }) => renderRowActions(row)}
                muiTableBodyRowProps={({ row }) => ({
                    onDoubleClick: () => {
                        if (rowDoubleClick) {
                            return rowDoubleClick(row.original);
                        }

                        if (enableUpdate) {
                            handleCreateUpdate(row.original);
                        }
                    },
                    sx: { cursor: 'pointer' },
                })}
                // Row right-click menu
                enableCellActions={!!rightClickMenuItems}
                renderCellActionMenuItems={({ cell, closeMenu }) => {
                    if (!rightClickMenuItems) {
                        return [];
                    }

                    return createMenuItems(rightClickMenuItems(cell.row.original), closeMenu, cell.row.original);
                }}
                // Toolbar actions
                renderTopToolbarCustomActions={() => (
                    <>
                        <Tooltip arrow title={'Refresh Data'}>
                            <IconButton onClick={() => refetch()}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
                // Filters
                manualFiltering={true}
                onColumnFiltersChange={(filters) => setColumnFilters(filters as Array<{ id: string; value: string }>)}
                onGlobalFilterChange={setGlobalFilter}
                // Pagination
                manualPagination={true}
                onPaginationChange={setPagination}
                // Sorting
                manualSorting={true}
                onSortingChange={setSorting}
                // Alerts
                muiToolbarAlertBannerProps={
                    isError
                        ? {
                              color: 'error',
                              children: 'Erro ao carregar dados.',
                          }
                        : undefined
                }
                // Styles
                muiTableContainerProps={{
                    sx: {
                        maxHeight: '100%',
                    },
                }}
                // States
                initialState={{
                    showGlobalFilter: URLSearchParams.get('search') !== null,
                    pagination: initialPagination,
                    sorting: initialSorting,
                    columnFilters: initialColumnFilters,
                    globalFilter: initialGlobalFilter,
                }}
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                    sorting,
                }}
            />
        </>
    );
};

const CrudTableWithQuery = <T extends TData>(props: CrudTableWithQueryProps<T>) => {
    const { columns, defaultSorting, defaultFilters, ...rest } = props;

    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    /**
     * INITIAL PARAMS
     */
    const initialGlobalFilter = URLSearchParams.get('search') ?? '';
    const initialSorting =
        URLSearchParams.getAll('ordering')?.map((ordering) => ({
            id: ordering.replaceAll('-', ''),
            desc: ordering.startsWith('-'),
        })) ?? defaultSorting;
    const initialPagination = {
        pageIndex: URLSearchParams.get('offset')
            ? Number(URLSearchParams.get('offset')) / Number(URLSearchParams.get('limit'))
            : 0,
        pageSize: URLSearchParams.get('limit') ? Number(URLSearchParams.get('limit')) : 10,
    };
    const initialColumnFilters = columns
        .map((col) => {
            const lookup = col.filterLookup ?? 'icontains';
            const filterField = getLookupFilterField(encodeFieldId(col.accessorKey), lookup);
            let filterValue = URLSearchParams.get(filterField) as any;

            if (!filterValue) {
                return null;
            }

            if (col.filterVariant === 'date') {
                filterValue = dayjs(filterValue);
            }

            return {
                id: col.accessorKey,
                value: filterValue,
            };
        })
        .filter(Boolean) as Array<{ id: string; value: any }>;

    /**
     * COMPONENT RENDER
     */
    return (
        <QueryClientProvider client={new QueryClient()}>
            <CrudTable
                // Props
                {...rest}
                columns={columns}
                // Initial params
                initialGlobalFilter={initialGlobalFilter}
                initialSorting={initialSorting}
                initialPagination={initialPagination}
                initialColumnFilters={
                    Object.keys(initialColumnFilters).length > 0 ? initialColumnFilters : defaultFilters ?? []
                }
            />
        </QueryClientProvider>
    );
};

export default CrudTableWithQuery;
