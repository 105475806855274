import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns } from 'src/components/crud/Crud.d';

import { Group } from './Group.d';

const name = {
    singular: 'Grupo de serviço',
    plural: 'Grupos de serviço',
};

const columns: Columns<Group> = [
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
];

const GroupsPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Manutenção', 'Cadastros', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.ServiceGroups}
                endpoint={'/api/v0/catalog/services/groups/'}
                columns={columns}
            />
        </>
    );
};

export default GroupsPage;
