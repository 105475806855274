import React, { useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMoreOutlined';
import PersonIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';

import AppRoutes from 'src/routes/appRoutes';
import { removeToken } from 'src/services/auth';
import { UserContext } from 'src/routes/privateRoutes';
import { removeUserData } from 'src/services/storage/user';

const UserItem = () => {
    const navigate = useNavigate();
    const user = useContext(UserContext);

    const [anchorElUser, setAnchorElUser] = useState<HTMLElement | null>(null);

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Button
                fullWidth
                disableElevation
                onClick={(e) => setAnchorElUser(e.currentTarget)}
                sx={{
                    minHeight: 55,
                    maxHeight: 55,
                    maxWidth: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                    gap: 1,
                    '&:hover': {
                        backgroundColor: '#00000011',
                    },
                }}
            >
                <Stack direction={'row'} spacing={1} alignItems={'center'} width={'100%'} maxWidth={'100%'}>
                    <Avatar
                        variant={'rounded'}
                        sizes={'small'}
                        sx={{
                            width: 32,
                            height: 32,
                        }}
                    >
                        {user?.first_name ? (
                            user.first_name[0]
                        ) : (
                            <Skeleton variant={'circular'} width={32} height={32} />
                        )}
                    </Avatar>

                    <Stack direction={'column'} maxWidth={'100%'} minWidth={0}>
                        <Typography
                            overflow={'hidden'}
                            textOverflow={'ellipsis'}
                            lineHeight={'0.9rem'}
                            fontSize={'0.9rem'}
                            textTransform={'none'}
                            whiteSpace={'nowrap'}
                            fontWeight={500}
                            textAlign={'left'}
                            color={'secondary'}
                        >
                            {user?.first_name ? `${user.first_name} ${user.last_name}` : <Skeleton width={100} />}
                        </Typography>
                        <Typography
                            overflow={'hidden'}
                            textOverflow={'ellipsis'}
                            variant={'caption'}
                            lineHeight={'0.8rem'}
                            textTransform={'none'}
                            whiteSpace={'nowrap'}
                            textAlign={'left'}
                            color={'secondary'}
                        >
                            {user?.email ? user.email : <Skeleton width={100} />}
                        </Typography>
                    </Stack>
                    <UnfoldMoreIcon
                        color={'secondary'}
                        sx={{
                            fontSize: '16px',
                        }}
                    />
                </Stack>
            </Button>

            <Menu
                keepMounted
                id={'menu-appbar'}
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: 230,
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleCloseUserMenu();
                        navigate(AppRoutes.UserSettings.path);
                    }}
                >
                    <PersonIcon fontSize={'small'} color={'primary'} sx={{ mr: 1 }} />
                    <Typography mr={3} children={'Meu Perfil'} />
                </MenuItem>

                <Divider />

                <MenuItem
                    onClick={() => {
                        handleCloseUserMenu();
                        removeToken();
                        removeUserData();
                        window.location.reload();
                    }}
                >
                    <LogoutIcon fontSize={'small'} color={'error'} sx={{ mr: 1 }} />
                    <Typography mr={3} children={'Sair'} />
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserItem;
