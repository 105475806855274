import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import ServiceIcon from '@mui/icons-material/HomeRepairService';

import CrudApiSelect from 'src/components/crud/fields/CrudApiSelect';
import CrudDatetimePicker from 'src/components/crud/fields/CrudDatetimePicker';
import CrudText from 'src/components/crud/fields/CrudText';
import DeleteModal from 'src/components/crud/modals/DeleteModal';
import getIdLabel from 'src/components/utils/getIdLabel';

import { Task } from 'src/pages/maintenances/Workorder';

import { TaskCardProps } from './TaskCard.d';

const name = {
    singular: 'tarefa',
    plural: 'tarefas',
};

const TaskCard = (props: TaskCardProps) => {
    const { taskValues, worId, refetch, setTaskValues } = props;

    /**
     * SUBMIT REMOVE
     */
    const [removeOpen, setRemoveOpen] = useState(false);
    const [removeEntity, setRemoveEntity] = useState<Task | null>(null);

    const openRemove = () => {
        setRemoveEntity(taskValues);
        setRemoveOpen(true);
    };

    /**
     * RENDER COMPONENT
     */
    if (!taskValues || !taskValues) {
        return <></>;
    }

    const valuesControl = {
        values: taskValues as Task,
        setValues: setTaskValues as Dispatch<SetStateAction<Task>>,
        readOnly: false,
        errors: {},
    };

    return (
        <>
            <Card variant={'outlined'}>
                <CardContent>
                    <Stack direction={'column'} pb={3} height={'100%'} spacing={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <ServiceIcon />
                                <Typography variant={'h6'} fontWeight={500} children={taskValues.service.description} />
                            </Stack>

                            <Stack direction={'row'} justifyContent={'end'} alignItems={'center'} spacing={1}>
                                <Typography
                                    variant={'body2'}
                                    fontWeight={500}
                                    color={'textSecondary'}
                                    children={`Tarefa ${getIdLabel(taskValues.id)}`}
                                />
                                <Divider orientation={'vertical'} flexItem={true} />
                                <Tooltip
                                    title={'Remover Tarefa'}
                                    placement={'top'}
                                    arrow={true}
                                    children={
                                        <IconButton
                                            color={'error'}
                                            onClick={() => openRemove()}
                                            children={<RemoveIcon />}
                                        />
                                    }
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={2}>
                            <FormControl>
                                <FormControlLabel
                                    label={'Concluído'}
                                    control={
                                        <Checkbox
                                            color={taskValues.is_done ? 'success' : 'default'}
                                            checked={taskValues.is_done}
                                            onChange={(e) => {
                                                setTaskValues({
                                                    ...taskValues,
                                                    is_done: e.target.checked,
                                                });
                                            }}
                                        />
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2.5}>
                            <CrudDatetimePicker
                                {...valuesControl}
                                size={'small'}
                                column={{
                                    accessorKey: 'start_date',
                                    header: 'Início',
                                    field: {
                                        type: 'datetime',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={2.5}>
                            <CrudDatetimePicker
                                {...valuesControl}
                                size={'small'}
                                column={{
                                    accessorKey: 'end_date',
                                    header: 'Fim',
                                    field: {
                                        type: 'datetime',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <CrudApiSelect
                                {...valuesControl}
                                size={'small'}
                                column={{
                                    accessorKey: 'responsible.name',
                                    header: 'Responsável',
                                    field: {
                                        type: 'search',
                                        name: 'responsible',
                                        endpoint: '/api/v0/registry/persons/',
                                        labelKey: (e) => (e ? e.name : ''),
                                        queryParams: {
                                            is_employee: true,
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CrudText
                                {...valuesControl}
                                size={'small'}
                                column={{
                                    accessorKey: 'observation',
                                    header: 'Observação',
                                    field: {
                                        type: 'text',
                                        multiline: true,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/** REMOVE CONFIRM */}
            <DeleteModal
                // STRUCTURE
                name={name}
                endpoint={`/api/v0/maintenances/workorders/${worId}/tasks/`}
                // MODAL CONTROL
                modalEntity={removeEntity}
                setOpen={setRemoveOpen}
                open={removeOpen}
                // ACTIONS
                refresh={refetch}
            />
        </>
    );
};

export default TaskCard;
