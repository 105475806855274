import React from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ChangeIcon from '@mui/icons-material/ImportExport';
import CloseIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';

import api from 'src/services/api';

import { RemoveMountingModalProps } from './RemoveMountingModal.d';

const RemoveMountingModal = (props: RemoveMountingModalProps) => {
    const { open, onClose, asset, equipment } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * HANDLE SUBMIT
     */
    const submitMountingRemove = async () => {
        if (!asset || !equipment) return;

        return await api.delete('/api/v0/fleet/allocations/current/mounting/', {
            data: {
                asset: asset.id,
                equipment: equipment.id,
            },
        });
    };

    const { mutate, isPending } = useMutation({
        mutationFn: submitMountingRemove,
        onSuccess: () => {
            onClose();
        },
        onError: (error: AxiosError<any>) => {
            enqueueSnackbar(error?.response?.data?.detail || 'Erro ao remover vínculo de instalação', {
                variant: 'error',
            });
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
            <DialogContent>
                <Stack direction={'row'} justifyContent={'space-between'} mb={1.5}>
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <ChangeIcon />
                        <Typography variant={'h6'} fontWeight={500} children={`Remover vínculo de instalação`} />
                    </Stack>

                    <Box>
                        <IconButton onClick={onClose} children={<CloseIcon />} />
                    </Box>
                </Stack>

                <Typography
                    mb={2}
                    variant={'subtitle1'}
                    children={'Você tem certeza que deseja remover este vínculo?'}
                />

                <Typography color={'error'} variant={'caption'}>
                    * Esta ação não pode ser revertida.
                </Typography>
            </DialogContent>
            <DialogActions
                sx={{
                    py: 1.5,
                    px: 3,
                    display: 'flex',
                    justifyContent: 'end',
                    borderTop: '1px solid #E0E0E0',
                    bgcolor: '#F9F9F9',
                }}
            >
                <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                <Button
                    variant={'contained'}
                    children={'Salvar'}
                    onClick={() => mutate()}
                    disabled={isPending}
                    startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                />
            </DialogActions>
        </Dialog>
    );
};

export default RemoveMountingModal;
