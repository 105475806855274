import React, { SetStateAction, useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import PlanIcon from '@mui/icons-material/Checklist';
import LooseIcon from '@mui/icons-material/FileDownloadDone';

import { Task } from 'src/pages/maintenances/Workorder';

import AddServiceModal from './AddServiceModal';
import TaskCard from './TaskCard';

import { TabServicesProps, TaskPlan } from './TabServices.d';

const TabServices = (props: TabServicesProps) => {
    const { values, setValues, refetch } = props;

    /**
     * ADD SERVICES MODAL
     */
    const [addServicesOpen, setAddServicesOpen] = useState(false);

    /**
     * GROUP TASKS BY PLAN
     */
    const plans: TaskPlan[] = useMemo(
        () =>
            Object.values(
                values.tasks.reduce((acc: Record<string, any>, task: Task) => {
                    const id = task?.plan?.id ?? 0;

                    if (!acc[id]) {
                        acc[id] = {
                            ...(id
                                ? task.plan
                                : {
                                      id: 0,
                                      description: 'Avulsos',
                                  }),
                            tasks: [],
                        };
                    }

                    acc[id].tasks.push(task);

                    return acc;
                }, {})
            ).sort((a, b) => (a.id === 0 ? 1 : b.id === 0 ? -1 : a.description.localeCompare(b.description))),
        [values.tasks]
    );

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'end'} my={2}>
                <Button
                    startIcon={<AddIcon />}
                    children={'Add Serviços'}
                    variant={'outlined'}
                    onClick={() => setAddServicesOpen(true)}
                />
            </Stack>

            <Stack spacing={6} pb={3}>
                {plans.map((plan) => (
                    <Stack key={`plan-${plan.id}`} spacing={1}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            {plan.id === 0 ? <LooseIcon /> : <PlanIcon />}
                            <Typography variant={'h6'} fontWeight={500} children={plan.description} />
                            <Divider sx={{ flexGrow: 1 }} />
                        </Stack>
                        {plan.tasks.map((task) => (
                            <TaskCard
                                key={`task-${task.id}`}
                                worId={values.id}
                                taskValues={task}
                                setTaskValues={(newTaskValues: SetStateAction<Task>) => {
                                    setValues((prev) => ({
                                        ...prev,
                                        tasks: prev.tasks.map((t: Task) => {
                                            if (t.id !== task.id) {
                                                return t;
                                            }

                                            if (typeof newTaskValues === 'function') {
                                                return newTaskValues(t);
                                            }

                                            return newTaskValues;
                                        }),
                                    }));
                                }}
                                refetch={refetch}
                            />
                        ))}
                    </Stack>
                ))}
            </Stack>

            {/* ADD SERVICES MODAL */}
            <AddServiceModal
                workorderId={values.id}
                open={addServicesOpen}
                onClose={() => setAddServicesOpen(false)}
                onAdd={(plans) => {
                    setAddServicesOpen(false);
                    refetch();
                }}
                // FILTERS TO THE SEARCH
                workorderPlanIds={plans.map((p) => p.id).filter((p) => p !== 0)}
                assetModelId={values.asset?.asset_model?.id}
                assetType={values.asset?.asset_type}
            />
        </>
    );
};

export default TabServices;
