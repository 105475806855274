import React from 'react';

import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ChangeIcon from '@mui/icons-material/SwapVert';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';

import { ChangeableItemProps } from './ChangeableItem.d';

export const ChangeableItem = (props: ChangeableItemProps) => {
    const { asset, isLoading, isFetched, variant = 'change', onClick } = props;

    /**
     * RENDER COMPONENT
     */
    return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Tooltip title={variant === 'change' ? 'Alterar' : 'Remover'}>
                <Button
                    endIcon={variant === 'change' ? <ChangeIcon color={'info'} /> : <RemoveIcon color={'error'} />}
                    onClick={() => onClick()}
                >
                    <Typography variant={'body1'}>
                        {isFetched ? (
                            asset?.description ?? 'Indefinido'
                        ) : (
                            <Skeleton variant={'text'} width={'100px'} animation={isLoading ? 'wave' : false} />
                        )}
                    </Typography>
                </Button>
            </Tooltip>
        </Stack>
    );
};

export default ChangeableItem;
