import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { TData } from 'src/components/crud/Crud.d';
import { CrudSelectProps } from './CrudSelect.d';

const CrudSelect = <T extends TData>(props: CrudSelectProps<T>) => {
    const { column, values, setValues, errors, readOnly = false } = props;

    const handleChange = (event: SelectChangeEvent) => {
        setValues({
            ...values,
            [column.accessorKey]: event.target.value,
        });
    };

    const options = column.field.options;

    return (
        <FormControl
            fullWidth
            key={column.accessorKey}
            error={errors?.hasOwnProperty(column.accessorKey) ?? false}
            size={column.field.size}
        >
            <InputLabel id={column.accessorKey} children={column.header} />
            <Select
                multiple={column.field?.multiple ?? false}
                id={column.accessorKey}
                labelId={column.accessorKey}
                value={values[column.accessorKey]}
                label={column.header}
                disabled={readOnly}
                onChange={handleChange}
            >
                {Object.keys(options).map((option) => {
                    return <MenuItem key={option} value={option} children={options[option]} />;
                })}
            </Select>
            {errors?.hasOwnProperty(column.accessorKey) && <FormHelperText children={errors[column.accessorKey]} />}
        </FormControl>
    );
};

export default CrudSelect;
