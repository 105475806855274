import axios from 'axios';

import {getToken, removeToken} from './auth';
import {removeUserData} from './storage/user/user';
import {removeCompaniesVisualData} from './storage/company/company';

/**
 * API FROM ENVIRONMENT
 */
const [http, domain] = (process.env.REACT_APP_API_URL ?? '').split('//');

// Get the subdomain from the current window location
const subdomain = window.location.host.replace('www.', '').split('.')[0];
export const apiURL = `${http}//${subdomain}.${domain}/`;

// Create an Axios instance with base URL set to the subdomain's server
const api = axios.create({
    baseURL: apiURL,
});

/**
 * Add an interceptor to attach the Authorization header with token to every request
 */
api.interceptors.request.use(async (config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
});

/**
 * Add an interceptor to handle response errors, such as unauthorized access
 */
api.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
        // Call a function to log the user out of the app
        removeToken();
        removeUserData();
        removeCompaniesVisualData();

        // Redirect the user to the login page
        if (window.location.toString() !== '/login') {
            window.location.replace('/login');
        }
    }
    return Promise.reject(error);
});

export default api;