import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns } from 'src/components/crud/Crud.d';

import { ASSET_TYPES_LABELS_MAP } from 'src/pages/fleet/Fleet.d';

import { AssetModel } from './AssetModel.d';

export const name = {
    singular: 'Modelo',
    plural: 'Modelos',
};
export const columns: Columns<AssetModel> = [
    {
        accessorKey: 'asset_type',
        header: 'Tipo de Ativo',
        field: {
            type: 'select',
            options: ASSET_TYPES_LABELS_MAP,
        },
        readOnly: (values) => !!values?.id,
        Cell: ({ row }) => ASSET_TYPES_LABELS_MAP[row.original.asset_type],
    },
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
    {
        accessorKey: 'brand',
        header: 'Marca',
        hideColumn: true,
        field: {
            type: 'search',
            name: 'brand',
            endpoint: '/api/v0/registry/brands/',
            labelKey: (brand) => (brand ? brand.description : ''),
            queryParams: {
                is_vehicle_brand: true,
            },
        },
    },
];

export const endpoint = '/api/v0/fleet/models/';

const AssetModelsPage = () => {
    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Configurações', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.AssetModels}
                endpoint={endpoint}
                columns={columns}
            />
        </>
    );
};

export default AssetModelsPage;
