import { ReadOnlyProp } from 'src/components/crud/Crud.d';

export const getIsReadOnly = (val: ReadOnlyProp, values: Record<string, any>): boolean => {
    if (!val) {
        return false;
    }

    if (typeof val === 'boolean') {
        return val;
    }

    return val(values);
};

export default getIsReadOnly;
