import React from 'react';

import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import SvgIcon from '@mui/material/SvgIcon/SvgIcon';

import CanceledIcon from '@mui/icons-material/Block';
import DoneIcon from '@mui/icons-material/CheckCircleOutline';
import PendingIcon from '@mui/icons-material/AccessTime';

export const STATUS = {
    PENDING: 'P',
    DONE: 'D',
    CANCELED: 'C',
};

export type Status = (typeof STATUS)[keyof typeof STATUS];

export const STATUS_LABELS: Record<Status, string> = {
    [STATUS.PENDING]: 'Pendente',
    [STATUS.DONE]: 'Faturada',
    [STATUS.CANCELED]: 'Cancelada',
};

export const STATUS_COLORS: Record<Status, 'warning' | 'info' | 'success' | 'error'> = {
    [STATUS.PENDING]: 'warning',
    [STATUS.DONE]: 'success',
    [STATUS.CANCELED]: 'error',
};

export const STATUS_ICONS: Record<Status, typeof SvgIcon> = {
    [STATUS.PENDING]: PendingIcon,
    [STATUS.DONE]: DoneIcon,
    [STATUS.CANCELED]: CanceledIcon,
};

export const StatusCell = (props: { status: Status }) => {
    const { status } = props;

    const Icon = STATUS_ICONS[status];
    return (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Icon fontSize={'small'} color={STATUS_COLORS[status]} />
            <Typography children={STATUS_LABELS[status]} />
        </Stack>
    );
};

export const StatusIcon = (props: { status: Status }) => {
    const { status } = props;

    const Icon = STATUS_ICONS[status];
    return (
        <Tooltip title={STATUS_LABELS[status]}>
            <Icon color={STATUS_COLORS[status]} />
        </Tooltip>
    );
};
