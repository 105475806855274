import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudPage from 'src/components/crud/CrudPage';
import { Columns } from 'src/components/crud/Crud.d';

import { AxelType } from './AxleType.d';

const name = {
    singular: 'Tipo de Rodado',
    plural: 'Tipos de Rodado',
};

const columns: Columns<AxelType> = [
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
];

const AxleTypePage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Configurações', name.plural]} />
                </Box>
            </Stack>

            <CrudPage
                // Structure
                name={name}
                route={AppRoutes.TrailerTypes}
                endpoint={'/api/v0/fleet/axle-types/'}
                columns={columns}
            />
        </>
    );
};

export default AxleTypePage;
