import { Route } from './appRoutes.d';

const AppRoutes = {
    Home: {
        path: '/manutencao/ordens-servico',
        module: 'maintenances',
        object: 'workorder',
    },

    // auth
    Login: {
        path: '/entrar',
    },
    ResetPassword: {
        path: '/redefinir-senha/:token',
    },

    // user
    UserSettings: {
        path: '/perfil',
        allowAny: true,
    },

    // admin
    Users: {
        path: '/usuarios',
        module: 'users',
        object: 'user',
    },
    Settings: {
        path: '/configs',
        superuser: true,
    },

    // registry
    Brands: {
        path: '/registros/marcas',
        module: 'registry',
        object: 'brand',
    },
    Colors: {
        path: '/registros/cores',
        module: 'registry',
        object: 'color',
    },
    Enterprises: {
        path: '/registros/empresas',
        module: 'registry',
        object: 'enterprise',
    },
    Persons: {
        path: '/registros/pessoas',
        module: 'registry',
        object: 'person',
    },

    // catalog
    MeasurementUnits: {
        path: '/catalogo/unidades-medida',
        module: 'catalog',
        object: 'measurementunit',
    },
    Bundles: {
        path: '/catalogo/conjuntos',
        module: 'catalog',
        object: 'bundle',
    },
    Requisitions: {
        path: '/catalogo/produtos/requisicoes',
        module: 'catalog',
        object: 'requisition',
    },

    // products
    ProductGroup: {
        path: '/catalogo/produtos/groups',
        module: 'catalog',
        object: 'productgroup',
    },
    ProductSubgroup: {
        path: '/catalogo/produtos/subgrupos',
        module: 'catalog',
        object: 'productsubgroup',
    },
    Products: {
        path: '/catalogo/produtos',
        module: 'catalog',
        object: 'product',
    },

    // services
    Services: {
        path: '/catalogo/servicos',
        module: 'catalog',
        object: 'service',
    },
    ServiceGroups: {
        path: '/catalogo/servicos/grupos',
        module: 'catalog',
        object: 'servicegroup',
    },
    ServiceSubgroups: {
        path: '/catalogo/servicos/subgroupos',
        module: 'catalog',
        object: 'servicesubgroup',
    },
    Plans: {
        path: '/catalogo/planos',
        module: 'catalog',
        object: 'plan',
    },

    // fleet
    CabinTypes: {
        path: '/frota/tipos-cabines',
        module: 'fleet',
        object: 'cabintype',
    },
    TrailerTypes: {
        path: '/frota/tipos-reboques',
        module: 'fleet',
        object: 'trailertype',
    },
    AxleTypes: {
        path: '/frota/tipos-rodados',
        module: 'fleet',
        object: 'axletype',
    },
    VehicleTypes: {
        path: '/frota/tipos-veiculos',
        module: 'fleet',
        object: 'vehicletype',
    },

    AssetGroup: {
        path: '/frota/grupos',
        module: 'fleet',
        object: 'group',
    },
    AssetSubgroup: {
        path: '/frota/subgrupos',
        module: 'fleet',
        object: 'subgroup',
    },
    AssetModels: {
        path: '/frota/modelos',
        module: 'fleet',
        object: 'assetmodel',
    },

    // assets
    Vehicles: {
        path: '/frota/veiculos',
        module: 'fleet',
        object: 'vehicle',
    },
    Implements: {
        path: '/implementos',
        module: 'fleet',
        object: 'implement',
    },
    Equipments: {
        path: '/equipamentos',
        module: 'fleet',
        object: 'equipment',
    },
    Allocations: {
        path: '/alocacoes',
        module: 'fleet',
        object: 'allocation',
    },

    // maintenances
    Workorders: {
        path: '/manutencao/ordens-servico',
        module: 'maintenances',
        object: 'workorder',
    },
};

export const getPermissionLabel = (route: Route, action: string) => {
    if (route.permissions) {
        if (route.permissions[action]) {
            return route.permissions[action];
        }
    }

    if (route.module && route.object) {
        return `${route.module}.${action}_${route.object}`;
    }

    return null;
};

export default AppRoutes;
