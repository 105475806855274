import React, { useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import BundleIcon from '@mui/icons-material/Workspaces';
import Chip from '@mui/material/Chip';

import ProductIcon from '@mui/icons-material/OilBarrel';
import ServiceIcon from '@mui/icons-material/HomeRepairService';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Clear';

import api from 'src/services/api';
import getIdLabel from 'src/components/utils/getIdLabel';

import { ItemRow } from 'src/pages/catalog/Plan/PlanModal/ItemsField/ItemsField.d';
import { Service } from 'src/pages/catalog/services/Service';
import { Product } from 'src/pages/catalog/products/Product';
import { Bundle } from 'src/pages/catalog/Bundle';

import { ITEM_TYPE_LABELS, ITEM_TYPES, ItemSelectorModalProps } from './ItemSelectorModal.d';

const columns = [
    {
        accessorKey: 'id',
        header: 'ID',
        Cell: (cell: any) => {
            const Icon =
                cell.row.original.type === ITEM_TYPES.SERVICE
                    ? ServiceIcon
                    : cell.row.original.type === ITEM_TYPES.PRODUCT
                      ? ProductIcon
                      : BundleIcon;

            const depth = cell.row.depth;
            return (
                <Stack direction={'row'} alignItems={'center'} spacing={1} pl={depth * 2}>
                    <Icon
                        fontSize={'small'}
                        sx={{
                            color: cell.row.original.type === ITEM_TYPES.BUNDLE ? 'info.main' : 'primary',
                        }}
                    />
                    <Box>{getIdLabel(cell.row.original.id)}</Box>
                </Stack>
            );
        },
        size: 100,
    },
    {
        accessorKey: 'description',
        header: 'Descrição',
        width: 300,
        Cell: (cell: any) => {
            const depth = cell.row.depth;
            return <Box pl={depth * 2}>{cell.cell.getValue()}</Box>;
        },
    },
    {
        accessorKey: 'type',
        header: 'Tipo',
        size: 100,
        Cell: (cell: any) => {
            const depth = cell.row.depth;
            return <Box pl={depth * 2}>{ITEM_TYPE_LABELS[cell.cell.getValue()]}</Box>;
        },
    },
];

export const formatItemRow = (item: Service | Product, type: 'service' | 'product'): ItemRow => {
    const isService = type === 'service';
    return {
        id: item.id.toString(),
        description: item.description,
        type: isService ? ITEM_TYPES.SERVICE : ITEM_TYPES.PRODUCT,
        rowId: `${isService ? 's' : 'p'}-${item.id}`,
    };
};

const ItemSelectorModal = (props: ItemSelectorModalProps) => {
    const { open, onClose, onAdd, selectedItems } = props;

    const [searchText, setSearchText] = useState<string>('');
    const [search, setSearch] = useState<string>('');

    const updateFilter = () => {
        setSearch(searchText);
    };

    /**
     * SERVICES
     */
    const fetchServices = async () => {
        return await api.get('/api/v0/catalog/services/', { params: { search } }).then((res) => res.data.results);
    };

    const { data: services, isFetching: isServicesLoading } = useQuery({
        queryKey: ['services', search],
        queryFn: fetchServices,
        enabled: !!search.length,
        initialData: [],
    });

    /**
     * PRODUCTS
     */
    const fetchProducts = async () => {
        return await api.get('/api/v0/catalog/products/', { params: { search } }).then((res) => res.data.results);
    };

    const { data: products, isFetching: isProductsLoading } = useQuery({
        queryKey: ['products', search],
        queryFn: fetchProducts,
        enabled: !!search.length,
        initialData: [],
    });

    /**
     * BUNDLES
     */
    const fetchBundles = async () => {
        return await api
            .get('/api/v0/catalog/bundles/', {
                params: {
                    search,
                    expanded: true,
                },
            })
            .then((res) => res.data.results);
    };

    const { data: bundles, isFetching: isBundlesLoading } = useQuery({
        queryKey: ['bundles', search],
        queryFn: fetchBundles,
        enabled: !!search.length,
        initialData: [],
    });

    /**
     * TABLE DATA
     */
    const data: ItemRow[] = useMemo(
        () =>
            [
                ...services.map((s: Service) => formatItemRow(s, 'service')),
                ...products.map((p: Product) => formatItemRow(p, 'product')),
                ...bundles
                    .map((b: Bundle) => ({
                        id: b.id,
                        description: b.description,
                        type: ITEM_TYPES.BUNDLE,
                        rowId: `b-${b.id}`,
                        subRows: [
                            ...b.services.map((s: Service) => formatItemRow(s, 'service')),
                            ...b.products.map((p: Product) => formatItemRow(p, 'product')),
                        ].filter((item) => !selectedItems.find((selected) => selected.rowId === item.rowId)),
                    }))
                    .filter((item: ItemRow) => item.subRows?.length),
            ].filter((item) => !selectedItems.find((selected) => selected.rowId === item.rowId)),
        [services, products, bundles, selectedItems]
    );
    const isLoading = isServicesLoading || isProductsLoading || isBundlesLoading;

    const table = useMaterialReactTable({
        columns,
        data,
        enableExpanding: true,
        enablePagination: false,
        enableBottomToolbar: false,
        enableTopToolbar: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                size: 5,
            },
        },
        // ROW ACTIONS
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => {
            const depth = row.depth;
            return (
                <Box pl={depth * 2}>
                    <Chip
                        size={'small'}
                        color={row.original.type == ITEM_TYPES.BUNDLE ? 'info' : 'primary'}
                        onClick={() => onAdd(row.original)}
                        label={
                            <Stack direction={'row'} alignItems={'center'}>
                                <AddIcon fontSize={'small'} />
                                {ITEM_TYPE_LABELS[row.original.type]}
                            </Stack>
                        }
                    />
                </Box>
            );
        },
        // STATES
        state: {
            density: 'compact',
            isLoading,
        },
        initialState: {
            pagination: { pageIndex: 0, pageSize: 100 },
            sorting: [
                {
                    id: 'type',
                    desc: false,
                },
                {
                    id: 'description',
                    desc: false,
                },
            ],
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
            <DialogContent sx={{ minHeight: '50vh' }}>
                <Stack direction={'column'} height={'100%'} spacing={2} pb={3}>
                    <Stack direction={'row'} justifyContent={'space-between'} pb={2.5}>
                        <Typography variant={'h6'} fontWeight={500} children={'Adicionar itens ao plano'} />

                        <Box>
                            <IconButton onClick={onClose} children={<CloseIcon />} />
                        </Box>
                    </Stack>
                    <Stack
                        direction={'row'}
                        spacing={1}
                        component={'form'}
                        onSubmit={(e) => {
                            e.preventDefault();
                            updateFilter();
                        }}
                    >
                        <TextField
                            fullWidth
                            size={'small'}
                            label={'Pesquisar'}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            required={true}
                        />
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            startIcon={isLoading ? <CircularProgress size={16} /> : <SearchIcon />}
                            disabled={isLoading}
                            type={'submit'}
                            sx={{
                                '& .MuiButton-startIcon': {
                                    pl: 1,
                                },
                                pr: 3,
                            }}
                            children={'Pesquisar'}
                        />
                    </Stack>
                    <MaterialReactTable table={table} />
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ItemSelectorModal;
