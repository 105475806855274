import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AllocationIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import ApiSelect from 'src/components/fields/ApiSelect';

import api from 'src/services/api';

import { Asset, ASSET_TYPES_LABELS_MAP } from 'src/pages/fleet/Fleet.d';
import { Vehicle } from 'src/pages/fleet/Vehicle';
import { Implement } from 'src/pages/fleet/Implement';
import { Equipment } from 'src/pages/fleet/Equipment';

import TruckImage from 'src/assets/img/truck.svg';
import TrailerImage from 'src/assets/img/trailer.svg';

import AddMountingModal from './AddMountingModal';
import ChangeableItem from './ChangeableItem';
import ChangeCouplingModal from './ChangeCouplingModal';
import EquipmentList from './EquipmentList';
import RemoveMountingModal from './RemoveMountingModal';

import { CurrentAllocationModalProps } from './CurrentAllocationModal.d';

const CurrentAllocationModal = (props: CurrentAllocationModalProps) => {
    const { open, onClose } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * FETCH CURRENT ALLOCATION
     */
    const [assetId, setAssetId] = useState<number | null>(null);

    const fetchCurrent = async () => {
        return await api.get(`/api/v0/fleet/allocations/current/asset/${assetId}/`).then((res) => {
            if (res.data.results.length) {
                return res.data.results[0];
            }
            return null;
        });
    };

    const { data, error, isLoading, isFetched, refetch } = useQuery({
        queryKey: ['currentAllocation', assetId],
        queryFn: fetchCurrent,
        enabled: !!assetId,
    });

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error.toString(), { variant: 'error' });
        }
    }, [error]);

    /**
     * SEARCH CONTROL
     */
    const [assetType, setAssetType] = useState<number | ''>('');
    const [asset, setAsset] = useState<Asset | null>(null);

    /**
     * COUPLING CONTROL
     */
    const [changeCouplingOpen, setChangeCouplingOpen] = useState(false);
    const [changeCouplingAsset, setChangeCouplingAsset] = useState<Vehicle | Implement | null>(null);

    const openChangeCoupling = (asset: Vehicle | Implement) => {
        setChangeCouplingOpen(true);
        setChangeCouplingAsset(asset);
    };

    /**
     * MOUNTING REMOVE CONTROL
     */
    const [removeMountingOpen, setRemoveMountingOpen] = useState(false);
    const [removeMountingAsset, setRemoveMountingAsset] = useState<Vehicle | Implement | null>(null);
    const [removeMountingEquipment, setRemoveMountingEquipment] = useState<Equipment | null>(null);

    const openMountingRemove = (asset: Vehicle | Implement, equipment: Equipment) => {
        setRemoveMountingOpen(true);
        setRemoveMountingAsset(asset);
        setRemoveMountingEquipment(equipment);
    };

    /**
     * MOUNTING ADD CONTROL
     */
    const [addMountingOpen, setAddMountingOpen] = useState(false);
    const [addMountingAsset, setAddMountingAsset] = useState<Vehicle | Implement | null>(null);

    const openMountingAdd = (asset: Vehicle | Implement) => {
        setAddMountingOpen(true);
        setAddMountingAsset(asset);
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Dialog open={open} maxWidth={'sm'} fullWidth>
                <DialogContent>
                    <Stack direction={'row'} justifyContent={'space-between'} pb={2.5}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <AllocationIcon />
                            <Typography variant={'h6'} fontWeight={500} children={'Alocação atual'} />
                        </Stack>

                        <Box>
                            <IconButton onClick={onClose} children={<CloseIcon />} />
                        </Box>
                    </Stack>

                    <Grid container spacing={2}>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth size={'small'}>
                                    <InputLabel id={'asset_type'} children={'Tipo de Ativo'} />
                                    <Select
                                        id={'asset_type'}
                                        labelId={'asset_type'}
                                        value={assetType}
                                        label={'Tipo de Ativo'}
                                        onChange={(e) => setAssetType(e.target.value as number)}
                                    >
                                        {Object.entries(ASSET_TYPES_LABELS_MAP).map(([key, value]) => (
                                            <MenuItem key={key} value={key} children={value} />
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                                <ApiSelect
                                    size={'small'}
                                    label={'Ativo'}
                                    endpoint={'/api/v0/fleet/assets/'}
                                    value={asset}
                                    onChange={(e, v) => setAsset(v)}
                                    getOptionLabel={(v) => v.description}
                                    queryParams={{
                                        asset_type: assetType,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    fullWidth
                                    variant={'contained'}
                                    color={'primary'}
                                    disabled={isLoading}
                                    children={'Buscar'}
                                    startIcon={isLoading ? <CircularProgress size={20} /> : <SearchIcon />}
                                    onClick={() => setAssetId(asset?.id ?? null)}
                                    sx={{
                                        h: '100%',
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} spacing={2} mb={6}>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction={'column'} alignItems={'center'} justifyContent={'right'} spacing={1}>
                                    <Box
                                        component={'img'}
                                        src={TruckImage}
                                        alt={'Truck'}
                                        maxWidth={'100%'}
                                        maxHeight={'60px'}
                                    />
                                    <ChangeableItem
                                        asset={data?.vehicle}
                                        isLoading={isLoading}
                                        isFetched={isFetched}
                                        onClick={() => openChangeCoupling(data?.implement)}
                                    />
                                    <EquipmentList
                                        equipmentList={data?.vehicle_equipments}
                                        disabled={!data?.vehicle}
                                        onAdd={() => openMountingAdd(data?.vehicle)}
                                        onRemove={(equipment) => openMountingRemove(data?.vehicle, equipment)}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={1}>
                                    <Box
                                        component={'img'}
                                        src={TrailerImage}
                                        alt={'Trailer'}
                                        maxWidth={'100%'}
                                        maxHeight={'60px'}
                                    />
                                    <ChangeableItem
                                        asset={data?.implement}
                                        isLoading={isLoading}
                                        isFetched={isFetched}
                                        onClick={() => openChangeCoupling(data?.vehicle)}
                                    />
                                    <EquipmentList
                                        equipmentList={data?.implement_equipments}
                                        disabled={!data?.implement}
                                        onAdd={() => openMountingAdd(data?.implement)}
                                        onRemove={(equipment) => openMountingRemove(data?.implement, equipment)}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <ChangeCouplingModal
                open={changeCouplingOpen}
                onClose={() => {
                    setChangeCouplingOpen(false);
                    refetch();
                }}
                asset={changeCouplingAsset}
            />

            <RemoveMountingModal
                open={removeMountingOpen}
                onClose={() => {
                    setRemoveMountingOpen(false);
                    refetch();
                }}
                asset={removeMountingAsset}
                equipment={removeMountingEquipment}
            />

            <AddMountingModal
                open={addMountingOpen}
                onClose={() => {
                    setAddMountingOpen(false);
                    refetch();
                }}
                asset={addMountingAsset}
            />
        </>
    );
};

export default CurrentAllocationModal;
