import React from 'react';

import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';

import { TData } from 'src/components/crud/Crud.d';

import { CrudDatetimePickerProps } from './CrudDatetimePicker.d';

const CrudDatetimePicker = <T extends TData>(props: CrudDatetimePickerProps<T>) => {
    const { column, values, setValues, errors, readOnly = false, size = 'medium' } = props;

    return (
        <DateTimePicker
            label={column.header}
            value={values[column.accessorKey] ? dayjs(values[column.accessorKey]) : null}
            disabled={readOnly}
            onChange={(e) => {
                setValues({
                    ...values,
                    [column.accessorKey]: e,
                });
            }}
            slotProps={{
                textField: {
                    size: size,
                    fullWidth: true,
                    error: errors?.hasOwnProperty(column.accessorKey) ?? false,
                    helperText: errors ? errors[column.accessorKey] ?? '' : '',
                },
            }}
        />
    );
};

export default CrudDatetimePicker;
