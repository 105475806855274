import React from 'react'

import 'dayjs/locale/pt-br'

import { SnackbarProvider } from 'notistack'
import { BrowserRouter as Router } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { createTheme } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { ptBR as corePtBr } from '@mui/material/locale'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ptBR } from '@mui/x-date-pickers/locales'

import { isAuthenticated } from './services/auth'
import PublicRoutes from './routes/publicRoutes'
import PrivateRoutes from './routes/privateRoutes'

const theme = createTheme(
    {
        palette: {
            primary: {
                main: '#101927',
            },
            secondary: {
                main: '#666666',
            },
            text: {
                primary: '#18181b',
                secondary: '#3f3f46',
                disabled: '#6b7280',
            },
        },
        typography: {
            fontFamily: [
                'Inter',
                'system-ui',
                'Avenir',
                'Helvetica',
                'Arial',
                'sans-serif',
            ].join(','),
            fontSize: 12,
        },
    },
    ptBR,
    corePtBr
)

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 5 * 1000, // 5 seconds
        },
    },
})

const MainComponent = (props: { authenticated: boolean }) => {
    const { authenticated } = props

    if (!authenticated) {
        return <PublicRoutes />
    }

    return <PrivateRoutes />
}

export default function App() {
    const authenticated = isAuthenticated()

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider
                localeText={
                    ptBR.components.MuiLocalizationProvider.defaultProps
                        .localeText
                }
                dateAdapter={AdapterDayjs}
                adapterLocale={'pt-br'}
            >
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider>
                        <Router>
                            <MainComponent authenticated={authenticated} />
                        </Router>
                    </SnackbarProvider>
                </QueryClientProvider>
            </LocalizationProvider>
        </ThemeProvider>
    )
}
