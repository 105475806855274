import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import LinkIcon from '@mui/icons-material/OpenInNew';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns } from 'src/components/crud/Crud.d';

import { Vehicle } from 'src/pages/fleet/Vehicle/Vehicle.d';
import VehicleModal from 'src/pages/fleet/Vehicle/VehicleModal';

const name = {
    singular: 'Veículo',
    plural: 'Veículos',
};

const columns: Columns<Vehicle> = [
    {
        accessorKey: 'fleet_id',
        header: 'ID Frota',
    },
    {
        accessorKey: 'license_plate',
        header: 'Placa',
    },
];

const VehiclesPage = () => {
    const navigate = useNavigate();

    /**
     * MODAL CONTROL
     */
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEntity, setModalEntity] = useState<Vehicle | null>(null);
    const [refresh, setRefresh] = useState(false);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Frota', 'Cadastros', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.Vehicles}
                endpoint={'/api/v0/fleet/vehicles/'}
                columns={columns}
                // Features
                controlledRefresh={refresh}
                setControlledRefresh={setRefresh}
                handleCreateUpdate={(entity: Vehicle | null) => {
                    setModalOpen(true);
                    setModalEntity(entity);
                }}
                // Actions
                extraCrudActions={() => (
                    <Stack justifyContent={'center'}>
                        <Button
                            variant={'outlined'}
                            onClick={() => navigate(AppRoutes.AssetModels.path)}
                            startIcon={<LinkIcon />}
                            children={'Modelos '}
                        />
                    </Stack>
                )}
            />

            <VehicleModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setModalEntity(null);
                    setRefresh(!refresh);
                }}
                vehicle={modalEntity}
            />
        </>
    );
};

export default VehiclesPage;
