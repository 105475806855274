import React from 'react'

import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { Route } from 'src/routes/appRoutes'

export interface NavItemProps {
    title: string
    route?: Route
    icon?: any
    image?: string
    onClick?: () => void
    setShow: (show: boolean) => void
    permissions: string[]
    superuser: boolean
}

const NavItem = (props: NavItemProps) => {
    const { title, icon: Icon, route, image, onClick, setShow } = props

    const navigate = useNavigate()

    const handleClick = () => {
        if (onClick) {
            onClick()
            return
        }

        setShow(false)

        if (route?.path) {
            navigate(route.path)
        }
    }

    return (
        <Button
            fullWidth
            disableElevation
            onClick={handleClick}
            sx={{
                minHeight: 40,
                maxHeight: 40,
                display: 'flex',
                justifyContent: 'start',
                gap: 1,
                '&:hover': {
                    backgroundColor: '#00000011',
                },
            }}
        >
            {Icon && (
                <Icon
                    sx={{
                        fontSize: '15px',
                    }}
                />
            )}
            {image && (
                <img width={20} height={20} src={image} alt={'sidebar'} />
            )}
            <Typography
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                textTransform={'none'}
                whiteSpace={'nowrap'}
                children={title}
            />
        </Button>
    )
}

export default NavItem
