import React, { useEffect, useRef, useState } from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { SelectTreeProps } from './SelectTree.d';
import CheckTree from './CheckTree';

const SelectTree = (props: SelectTreeProps) => {
    const { onCheck, checked, itemsEndpoint, checkedItemsEndpoint, remapKeys, enabled = true } = props;

    /**
     * SEARCH CONTROL
     */
    const [searchText, setSearchText] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const typingTimeoutRef = useRef<any>(null);

    useEffect(() => {
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => setSearch(searchText), 500);

        return () => clearTimeout(typingTimeoutRef.current);
    }, [searchText]);

    /**
     * RENDER COMPONENT
     */
    return (
        <Stack flexGrow={1} spacing={2}>
            <Stack direction={'row'} justifyContent={'end'}>
                <TextField
                    placeholder={'Pesquisar'}
                    variant={'standard'}
                    size={'small'}
                    value={searchText}
                    InputProps={{
                        startAdornment: <InputAdornment position={'start'} children={<SearchIcon />} />,
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Stack>

            <CheckTree
                onCheck={onCheck}
                checked={checked}
                itemsEndpoint={itemsEndpoint}
                checkedItemsEndpoint={checkedItemsEndpoint}
                remapKeys={remapKeys}
                enabled={enabled}
                search={search}
            />
        </Stack>
    );
};

export default SelectTree;
