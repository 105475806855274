import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import BundleIcon from '@mui/icons-material/Workspaces';
import CloseIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';

import api from 'src/services/api';
import getIdLabel from 'src/components/utils/getIdLabel';

import CrudSelect from 'src/components/crud/fields/CrudSelect';
import CrudText from 'src/components/crud/fields/CrudText';
import SelectTree from 'src/components/fields/SelectTree';

import { TData } from 'src/components/crud/Crud.d';
import { concatFieldErrors } from 'src/components/crud/modals/CreateUpdateModal';

import { BundleModalProps } from './BundleModal.d';

const TABS = {
    PRODUCTS: 0,
    SERVICES: 1,
};

const defaultValues = {
    id: 0,
    description: '',
    services: [],
    products: [],
    is_active: true,
};

const BundleModal = (props: BundleModalProps) => {
    const { open, onClose, bundle } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * TABS CONTROL
     */
    const [selectedTab, setSelectedTab] = useState<number>(0);

    /**
     * VALUES CONTROL
     */
    const [values, setValues] = useState<TData>(defaultValues);
    const [services, setServices] = useState<string[]>([]);
    const [products, setProducts] = useState<string[]>([]);
    const [errors, setErrors] = useState({});

    const valuesControl = {
        values,
        setValues,
        errors,
        setErrors,
    };

    /**
     * DATA LOAD
     */
    useEffect(() => {
        if (open) {
            if (bundle) {
                setValues({
                    id: bundle.id,
                    description: bundle.description,
                    is_active: bundle.is_active,
                } as TData);
            } else {
                setValues(defaultValues);
            }
        }
    }, [open]);

    /**
     * VALUES SUBMIT
     */
    const submitChanges = async () => {
        const data: Record<string, any> = {
            ...values,
        };

        // convert nested objects to id
        ['subgroup'].forEach((key) => {
            if (key in data) {
                data[key] = !data[key] || data[key].id === 0 || typeof data[key] !== 'object' ? null : data[key].id;
            }
        });

        data['products'] = products;
        data['services'] = services;

        delete data['group'];

        // clear empty fields
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });

        if (bundle) {
            return await api.put(`/api/v0/catalog/bundles/${bundle.id}/`, data).then((response) => response.data);
        }

        return await api.post('/api/v0/catalog/bundles/', data).then((response) => response.data);
    };

    const { mutate: handleSave, isPending } = useMutation({
        mutationFn: submitChanges,
        throwOnError: false,
        onError: (error: AxiosError<any>) => {
            try {
                let detail = null;
                let errors = error.response?.data as Record<string, string[]>;

                if (error.response?.data.hasOwnProperty('detail')) {
                    detail = errors.detail;
                    delete errors.detail;
                }

                let fieldErrors = concatFieldErrors(errors);

                setErrors(fieldErrors);

                if (detail) {
                    enqueueSnackbar(detail, { variant: 'error' });
                }
            } catch (e) {
                enqueueSnackbar('Erro ao salvar!', { variant: 'error' });
            }
        },
        onSuccess: () => {
            enqueueSnackbar('Conjunto salvo com sucesso.', { variant: 'success' });
            setErrors({});
            onClose();
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Dialog open={open} maxWidth={'lg'} fullWidth>
                <DialogContent sx={{ height: '90vh' }}>
                    <Stack direction={'column'} pb={3} height={'100%'}>
                        <Stack direction={'row'} justifyContent={'space-between'} pb={2.5}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <BundleIcon />
                                <Typography
                                    variant={'h6'}
                                    fontWeight={500}
                                    children={bundle ? `Conjunto ${getIdLabel(bundle.id)}` : 'Novo Conjunto'}
                                />
                            </Stack>

                            <Box>
                                <IconButton onClick={onClose} children={<CloseIcon />} disabled={isPending} />
                            </Box>
                        </Stack>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <CrudText
                                    {...valuesControl}
                                    column={{
                                        accessorKey: 'description',
                                        header: 'Descrição',
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Stack height={'100%'} pt={2}>
                            <Box borderBottom={1} borderColor={'divider'}>
                                <Tabs value={selectedTab} onChange={(e, t) => setSelectedTab(t)}>
                                    <Tab label={'Produtos'} id={TABS.PRODUCTS.toString()} />
                                    <Tab label={'Serviços'} id={TABS.SERVICES.toString()} />
                                </Tabs>
                            </Box>

                            <Stack flex={1} pt={2}>
                                {selectedTab === TABS.PRODUCTS && (
                                    <SelectTree
                                        enabled={open && selectedTab === TABS.PRODUCTS}
                                        itemsEndpoint={'/api/v0/catalog/products/groups/tree/'}
                                        checkedItemsEndpoint={
                                            values.id ? `/api/v0/catalog/bundles/${values.id}/products/` : undefined
                                        }
                                        remapKeys={[
                                            { childrenKey: 'subgroups', labelKey: 'description' },
                                            { childrenKey: 'products', labelKey: 'description' },
                                            { labelKey: 'description' },
                                        ]}
                                        checked={products}
                                        onCheck={(checked) => setProducts(checked)}
                                    />
                                )}
                                {selectedTab === TABS.SERVICES && (
                                    <SelectTree
                                        enabled={open && selectedTab === TABS.SERVICES}
                                        itemsEndpoint={'/api/v0/catalog/services/groups/tree/'}
                                        checkedItemsEndpoint={
                                            values.id ? `/api/v0/catalog/bundles/${values.id}/services/` : undefined
                                        }
                                        remapKeys={[
                                            { childrenKey: 'subgroups', labelKey: 'description' },
                                            { childrenKey: 'services', labelKey: 'description' },
                                            { labelKey: 'description' },
                                        ]}
                                        checked={services}
                                        onCheck={(checked) => setServices(checked)}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </DialogContent>

                <DialogActions
                    sx={{
                        py: 1.5,
                        px: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderTop: '1px solid #E0E0E0',
                        bgcolor: '#F9F9F9',
                    }}
                >
                    <Box width={150}>
                        <CrudSelect
                            {...valuesControl}
                            column={{
                                accessorKey: 'is_active',
                                header: 'Status',
                                field: {
                                    size: 'small',
                                    type: 'select',
                                    options: {
                                        true: 'Ativo',
                                        false: 'Inativo',
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Stack direction={'row'} spacing={1} p={1}>
                        <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                        <Button
                            variant={'contained'}
                            children={'Salvar'}
                            onClick={() => handleSave()}
                            disabled={isPending}
                            startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                        />
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default BundleModal;
