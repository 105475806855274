import React from 'react';

import {canAccessRoute} from '../../../services/storage/permissions';
import Forbidden from '../../../pages/errors/Forbidden';

import {RouteWithPermissionsProps} from '../RouteWithPermissions';


const RouteWithPermissions = (props: RouteWithPermissionsProps) => {

    const {
        element,
        route,
        superuser = false,
        permissions = [],
    } = props;

    if (!canAccessRoute(permissions, superuser, route)) {
        return (<Forbidden/>);
    }

    return element;
};

export default RouteWithPermissions;