import React, { useState } from 'react';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import AddIcon from '@mui/icons-material/Add';
import BundleIcon from '@mui/icons-material/Workspaces';
import DeleteIcon from '@mui/icons-material/Delete';
import ProductIcon from '@mui/icons-material/OilBarrel';
import ServiceIcon from '@mui/icons-material/HomeRepairService';

import getIdLabel from 'src/components/utils/getIdLabel';

import ItemSelectorModal, { ITEM_TYPES } from './ItemsSelectorModal';
import { ItemRow, ItemsFieldProps } from './ItemsField.d';

const columns = [
    {
        accessorKey: 'id',
        header: 'ID',
        Cell: (cell: any) => {
            const Icon =
                cell.row.original.type === ITEM_TYPES.SERVICE
                    ? ServiceIcon
                    : cell.row.original.type === ITEM_TYPES.PRODUCT
                      ? ProductIcon
                      : BundleIcon;

            const depth = cell.row.depth;
            return (
                <Stack direction={'row'} alignItems={'center'} spacing={1} pl={depth * 2}>
                    <Icon
                        fontSize={'small'}
                        sx={{
                            color: cell.row.original.type === ITEM_TYPES.BUNDLE ? 'info.main' : 'primary',
                        }}
                    />
                    <Box>{getIdLabel(cell.row.original.id, 'novo')}</Box>
                </Stack>
            );
        },
        size: 100,
    },
    {
        accessorKey: 'description',
        header: 'Descrição',
        width: 300,
        Cell: (cell: any) => {
            const depth = cell.row.depth;
            return <Box pl={depth * 2}>{cell.cell.getValue()}</Box>;
        },
    },
];

const ItemsField = (props: ItemsFieldProps) => {
    const { items, onChange } = props;

    /**
     * TABLE DATA
     */
    const table = useMaterialReactTable({
        columns,
        data: items,
        enablePagination: false,
        enableBottomToolbar: false,
        enableTopToolbar: false,
        // STATES
        state: {
            density: 'compact',
        },
        initialState: {
            pagination: { pageIndex: 0, pageSize: 100 },
        },
        // ROW ACTIONS
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => {
            return (
                <Box>
                    <Tooltip title={'Remover item'}>
                        <IconButton
                            children={<DeleteIcon color={'error'} />}
                            onClick={() => {
                                const newItems = items.filter((item) => item.rowId !== row.original.rowId);
                                onChange(newItems, row.original);
                            }}
                        />
                    </Tooltip>
                </Box>
            );
        },
    });

    /**
     * ITEM SELECTOR MODAL
     */
    const [itemSelectorOpen, setItemSelectorOpen] = useState(false);

    const handleAddItem = (itemRow: ItemRow) => {
        let newItems: ItemRow[];

        if (itemRow.type === ITEM_TYPES.BUNDLE) {
            newItems = itemRow.subRows ?? [];
        } else {
            newItems = [itemRow];
        }

        for (const newItem of newItems) {
            if (items.find((item) => item.id === newItem.id && item.type === newItem.type)) {
                continue;
            }

            items.push(newItem);
        }

        onChange([...items], itemRow);
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack spacing={2}>
                <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Button
                        variant={'contained'}
                        startIcon={<AddIcon />}
                        children={'Adicionar Item'}
                        onClick={() => setItemSelectorOpen(true)}
                    />
                </Stack>

                <MaterialReactTable table={table} />
            </Stack>

            <ItemSelectorModal
                open={itemSelectorOpen}
                onClose={() => setItemSelectorOpen(false)}
                onAdd={handleAddItem}
                selectedItems={items}
            />
        </>
    );
};
export default ItemsField;
