import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Clear';

import AllocationIcon from '@mui/icons-material/Link';
import SaveIcon from '@mui/icons-material/Save';

import api from 'src/services/api';

import CrudApiSelect from 'src/components/crud/fields/CrudApiSelect';
import CrudDatetimePicker from 'src/components/crud/fields/CrudDatetimePicker';
import CrudSelect from 'src/components/crud/fields/CrudSelect';

import { TData } from 'src/components/crud/Crud.d';
import { concatFieldErrors } from 'src/components/crud/modals/CreateUpdateModal';
import { ASSET_TYPES } from 'src/pages/fleet/Fleet.d';

import { AllocationModalProps } from './AllocationModal.d';

const defaultValues = {
    id: 0,
    asset_type1: ASSET_TYPES.VEHICLE,
    asset1: {
        id: 0,
        description: '',
    },
    asset_type2: ASSET_TYPES.IMPLEMENT,
    asset2: {
        id: 0,
        description: '',
    },
    start_date: dayjs(),
    end_date: '',
};

const AllocationModal = (props: AllocationModalProps) => {
    const { open, onClose, allocation } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * VALUES CONTROL
     */
    const [values, setValues] = useState<TData>(defaultValues);
    const [errors, setErrors] = useState({});

    const valuesControl = {
        values,
        setValues,
        errors,
        setErrors,
    };

    /**
     * DATA LOAD
     */
    useEffect(() => {
        if (open) {
            if (allocation) {
                setValues({
                    ...Object.fromEntries(
                        Object.entries(allocation).map(([key, value]) => [key, value === null ? '' : value])
                    ),
                    id: allocation.id,
                    asset_type1: allocation.asset1?.asset_type,
                    asset_type2: allocation.asset2?.asset_type,
                } as TData);
            } else {
                setValues(defaultValues);
            }
        }
    }, [allocation, open]);

    /**
     * VALUES SUBMIT
     */
    const submitData = async () => {
        const data: Record<string, any> = {
            ...values,
        };

        // convert nested objects to id
        ['asset1', 'asset2'].forEach((key) => {
            if (key in data) {
                data[key] = !data[key] || data[key].id === 0 ? null : data[key].id;
            }
        });

        if (data.end_date === '') {
            delete data.end_date;
        }

        if (allocation) {
            return await api.put(`/api/v0/fleet/allocations/${allocation.id}/`, data).then((response) => response.data);
        }

        return await api.post('/api/v0/fleet/allocations/', data).then((response) => response.data);
    };

    const { mutate: handleSave, isPending } = useMutation({
        mutationFn: submitData,
        throwOnError: false,
        onError: (error: AxiosError<any>) => {
            try {
                let detail = null;
                let errors = error.response?.data as Record<string, string[]>;

                if (error.response?.data.hasOwnProperty('detail')) {
                    detail = errors.detail;
                    delete errors.detail;
                }

                let fieldErrors = concatFieldErrors(errors);

                setErrors(fieldErrors);

                if (detail) {
                    enqueueSnackbar(detail, { variant: 'error' });
                }
            } catch (e) {
                enqueueSnackbar('Erro ao salvar!', { variant: 'error' });
            }
        },
        onSuccess: () => {
            enqueueSnackbar('Equipamento salvo com sucesso.', { variant: 'success' });
            setErrors({});
            onClose();
        },
    });

    /**
     * FORM
     */
    const assetTypes2 = useMemo(() => {
        let assetTypes = { [ASSET_TYPES.EQUIPMENT]: 'Equipamento' };

        if (parseInt(values.asset_type1) === ASSET_TYPES.VEHICLE) {
            assetTypes[ASSET_TYPES.IMPLEMENT] = 'Implemento';
        }

        return assetTypes;
    }, [values.asset_type1]);

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth>
            <DialogContent>
                <Stack direction={'column'} pb={3}>
                    <Stack direction={'row'} justifyContent={'space-between'} pb={2.5}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <AllocationIcon />
                            <Typography
                                variant={'h6'}
                                fontWeight={500}
                                children={allocation ? `Alocação ${allocation.id} ` : 'Nova Alocação'}
                            />
                        </Stack>

                        <Box>
                            <IconButton onClick={onClose} children={<CloseIcon />} disabled={isPending} />
                        </Box>
                    </Stack>

                    <Grid container spacing={2}>
                        <Grid item container spacing={2} xs={12}>
                            <Grid item xs={4}>
                                <CrudSelect
                                    {...valuesControl}
                                    column={{
                                        accessorKey: 'asset_type1',
                                        header: 'Tipo de Ativo',
                                        field: {
                                            type: 'select',
                                            options: {
                                                [ASSET_TYPES.VEHICLE]: 'Veículo',
                                                [ASSET_TYPES.IMPLEMENT]: 'Implemento',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <CrudApiSelect
                                    {...valuesControl}
                                    column={{
                                        accessorKey: 'asset1.description',
                                        header: 'Ativo',
                                        field: {
                                            type: 'search',
                                            name: 'asset1',
                                            endpoint: '/api/v0/fleet/assets/',
                                            labelKey: (e) => (e ? e.description : ''),
                                            queryParams: {
                                                asset_type: values.asset_type1,
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2} xs={12}>
                            <Grid item xs={4}>
                                <CrudSelect
                                    {...valuesControl}
                                    column={{
                                        accessorKey: 'asset_type2',
                                        header: 'Tipo de Ativo 2',
                                        field: {
                                            type: 'select',
                                            options: assetTypes2,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <CrudApiSelect
                                    {...valuesControl}
                                    column={{
                                        accessorKey: 'asset2.description',
                                        header: 'Ativo 2',
                                        field: {
                                            type: 'search',
                                            name: 'asset2',
                                            endpoint: '/api/v0/fleet/assets/',
                                            labelKey: (e) => (e ? e.description : ''),
                                            queryParams: {
                                                asset_type: values.asset_type2,
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <Divider flexItem={true} />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2} xs={12}>
                            <Grid item xs={6}>
                                <CrudDatetimePicker
                                    {...valuesControl}
                                    column={{
                                        accessorKey: 'start_date',
                                        header: 'Início',
                                        field: {
                                            type: 'datetime',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CrudDatetimePicker
                                    {...valuesControl}
                                    column={{
                                        accessorKey: 'end_date',
                                        header: 'Fim',
                                        field: {
                                            type: 'datetime',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Stack>
            </DialogContent>

            <DialogActions
                sx={{
                    py: 1.5,
                    px: 3,
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderTop: '1px solid #E0E0E0',
                    bgcolor: '#F9F9F9',
                }}
            >
                <Box width={150}>
                    <CrudSelect
                        {...valuesControl}
                        column={{
                            accessorKey: 'is_active',
                            header: 'Status',
                            field: {
                                size: 'small',
                                type: 'select',
                                options: {
                                    true: 'Ativo',
                                    false: 'Inativo',
                                },
                            },
                        }}
                    />
                </Box>
                <Stack direction={'row'} spacing={1} p={1}>
                    <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                    <Button
                        variant={'contained'}
                        children={'Salvar'}
                        onClick={() => handleSave()}
                        disabled={isPending}
                        startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default AllocationModal;
