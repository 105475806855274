import RouteWithPermissions from '../RouteWithPermissions';
import RouteWithSidebar from '../RouteWithSidebar';
import {RouteOfSystemProps} from '../RouteOfSystem';


const RouteOfSystem = (props: RouteOfSystemProps) => {

    const {
        page,
        superuser,
        permissions,
    } = props;

    return (<RouteWithPermissions
        element={<RouteWithSidebar component={page.component}/>}
        route={page.route}
        superuser={superuser}
        permissions={permissions}
    />);

};

export default RouteOfSystem;