import React from 'react';

import InputMask from 'react-input-mask';

import TextField from '@mui/material/TextField';

import getNestedValue from 'src/components/utils/getNestedValue';
import setNestedValue from 'src/components/utils/setNestedValue';
import hasNestedValue from 'src/components/utils/hasNestedValue';

import { TData } from 'src/components/crud/Crud.d';

import { CrudMaskTextProps } from './CrudMaskText.d';

const CrudMaskText = <T extends TData>(props: CrudMaskTextProps<T>) => {
    const { column, values, setValues, errors, readOnly, required } = props;

    return (
        <InputMask
            mask={column.field.mask}
            disabled={readOnly}
            value={getNestedValue(values, column.accessorKey, '')}
            onChange={(e) => {
                setValues(setNestedValue(values, column.accessorKey, e.target.value.toUpperCase()));
            }}
        >
            <TextField
                fullWidth
                disabled={readOnly}
                label={column.header}
                name={column.accessorKey}
                required={required}
                error={hasNestedValue(errors, column.accessorKey)}
                helperText={errors ? getNestedValue(errors, column.accessorKey, '') : ''}
            />
        </InputMask>
    );
};

export default CrudMaskText;
