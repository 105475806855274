import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import api from 'src/services/api';
import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns, RightClickMenuItem } from 'src/components/crud/Crud.d';

import { STATUS, Status, STATUS_COLORS, STATUS_ICONS, STATUS_LABELS, StatusIcon } from './Status.d';
import { CATEGORIES_LABELS } from './Category.d';
import { Workorder } from './Workorder.d';
import WorkorderModal from './WorkorderModal';
import CreateWorkorderModal from './CreateWorkorderModal';
import PageFilters, { ExtraFilters } from './PageFilters';

const name = {
    singular: 'Ordem de Serviço',
    plural: 'Ordens de Serviço',
};

const columns: Columns<Workorder> = [
    {
        accessorKey: 'status',
        header: '',
        Cell: (cell: any) => <StatusIcon status={cell.row.original.status} />,
        size: 20,
        grow: false,
        enableSorting: false,
        enableResizing: false,
        enableColumnFilter: false,
        enableColumnActions: false,
    },
    {
        accessorKey: 'id',
        header: 'Id',
    },
    {
        accessorKey: 'asset.description',
        header: 'Descrição do Ativo',
    },
    {
        accessorKey: 'category',
        header: 'Categoria',
        Cell: (cell: any) => CATEGORIES_LABELS[cell.row.original.category],
    },
];

const endpoint = '/api/v0/maintenances/workorders/';

const WorkordersPage = () => {
    const { enqueueSnackbar } = useSnackbar();

    /**
     * FILTERS
     */
    const [extraFilters, setExtraFilters] = useState<ExtraFilters>([]);
    const [statusCount, setStatusCount] = useState(null);

    /**
     * MODAL CONTROL
     */
    const [refresh, setRefresh] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [modalEntity, setModalEntity] = useState<Workorder | null>(null);

    /**
     * STAGE CONTROL
     */
    const submitStatus = async (props: { entity: Workorder; status: Status }) => {
        const { entity, status } = props;

        return await api
            .patch(`${endpoint}${entity.id}/`, { status })
            .then(() => {
                setRefresh((v) => !v);
                enqueueSnackbar('Status atualizado com sucesso.', { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar(err?.response?.data.detail ?? 'Erro ao atualizar status.', { variant: 'error' });
            });
    };

    const { mutate: changeStatus, isPending: isPendingStatus } = useMutation<
        void,
        Error,
        { entity: Workorder; status: Status }
    >({
        mutationFn: submitStatus,
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Manutenção', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.Workorders}
                endpoint={endpoint}
                columns={columns}
                // Features
                controlledRefresh={refresh}
                setControlledRefresh={setRefresh}
                handleCreateUpdate={(entity: Workorder | null) => {
                    if (entity) {
                        setEditOpen(true);
                    } else {
                        setCreateOpen(true);
                    }
                    setModalEntity(entity);
                }}
                // FILTERS
                extraFilters={extraFilters}
                onLoad={(data: Record<string, any>) => {
                    setStatusCount(data.count_by_status);
                }}
                extraCrudActions={() => (
                    <PageFilters
                        extraFilters={extraFilters}
                        setExtraFilters={setExtraFilters}
                        statusCount={statusCount}
                    />
                )}
                // Actions
                rightClickMenuItems={(entity: Workorder) => [
                    {
                        label: 'Definir Etapa',
                        childs: Object.values(STATUS).map((status) => {
                            const Icon = STATUS_ICONS[status];
                            return {
                                label: STATUS_LABELS[status],
                                icon: <Icon color={STATUS_COLORS[status]} />,
                                onClick: () => changeStatus({ entity, status }),
                            };
                        }) as RightClickMenuItem<Workorder>[],
                    },
                    {
                        label: 'Editar',
                        onClick: () => {
                            setModalEntity(entity);
                            setEditOpen(true);
                        },
                    },
                ]}
            />

            {/* WORKORDER EDIT */}
            {modalEntity && (
                <WorkorderModal
                    open={editOpen}
                    onClose={() => {
                        setEditOpen(false);
                        setModalEntity(null);
                        setRefresh(!refresh);
                    }}
                    workorder={modalEntity}
                />
            )}

            {/* WORKORDER CREATE */}
            <CreateWorkorderModal
                open={createOpen}
                onClose={() => {
                    setCreateOpen(false);
                    setRefresh(!refresh);
                }}
                onCreate={(workorder) => {
                    setModalEntity(workorder);
                    setRefresh(!refresh);
                    setCreateOpen(false);
                    setEditOpen(true);
                }}
            />
        </>
    );
};

export default WorkordersPage;
