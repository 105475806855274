import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import ClassIcon from '@mui/icons-material/Bookmarks';
import CloseIcon from '@mui/icons-material/Clear';
import DocsIcon from '@mui/icons-material/Description';
import ImplementIcon from '@mui/icons-material/RvHookup';
import ImplementInfoIcon from '@mui/icons-material/Assignment';
import InfoIcon from '@mui/icons-material/Info';
import MoneyIcon from '@mui/icons-material/MonetizationOn';
import RemoveIcon from '@mui/icons-material/DeleteOutline';
import SaveIcon from '@mui/icons-material/Save';
import TiresIcon from '@mui/icons-material/TireRepair';

import api from 'src/services/api';

import CrudApiSelect from 'src/components/crud/fields/CrudApiSelect';
import CrudDatePicker from 'src/components/crud/fields/CrudDatePicker';
import CrudMask from 'src/components/crud/fields/CrudMask';
import CrudMaskText from 'src/components/crud/fields/CrudMaskText';
import CrudSelect from 'src/components/crud/fields/CrudSelect';
import CrudText from 'src/components/crud/fields/CrudText';

import { columns as modelColumns, endpoint as modelEndpoint, name as modelName } from 'src/pages/fleet/AssetModel';
import { CATEGORIES } from 'src/pages/catalog/products/Product';
import { columns as colorColumns, endpoint as colorEndpoint, name as colorName } from 'src/pages/registry/Color';

import { TData } from 'src/components/crud/Crud.d';
import ModalSection from 'src/components/ModalSection';
import CreateUpdateModal, { concatFieldErrors } from 'src/components/crud/modals/CreateUpdateModal';
import ProductModal from 'src/pages/catalog/products/Product/ProductModal';

import { ASSET_TYPES, AssetTire, PROPERTY_TYPES, PROPERTY_TYPES_LABELS_MAP } from 'src/pages/fleet/Fleet.d';

import { ImplementModalProps } from './ImplementModal.d';

const defaultTire = {
    id: 0,
    tire: '',
    brand: '',
    quantity: 1,
    dot: 0,
};

const defaultValues = {
    id: 0,
    implement_brand: {
        id: 0,
        description: '',
    },
    asset_model: '',
    color: '',
    manufacture_year: '',
    model_year: '',
    chassi_id: '',
    serial_id: '',
    group: {
        id: 0,
        description: '',
    },
    subgroup: {
        id: 0,
        description: '',
    },
    axle_type: '',
    trailer_type: '',
    property: '',
    owner: {
        id: 0,
        name: '',
    },
    invoice_id: '',
    country: {
        id: 0,
        description: '',
    },
    state: {
        id: 0,
        description: '',
    },
    city: '',
    license_plate: '',
    renavam: '',
    empty_weight: '',
    legal_weight: '',
    capacity: '',
    volumetric_capacity: '',
    axel_quantity: '',
    pallet_capacity: '',
    fleet_id: '',
    initial_hour_counter: '',
    purchase_date: '',
    purchase_value: '',
    purchase_depreciation: '',
    warranty_date: '',
    maintenance_plan_start: '',
    maintenance_plan_end: '',
    tires: [],
    is_active: true,
};

const ImplementModal = (props: ImplementModalProps) => {
    const { open, onClose, implement } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * VALUES CONTROL
     */
    const [values, setValues] = useState<TData>(defaultValues);
    const [errors, setErrors] = useState({});

    const valuesControl = {
        values,
        setValues,
        errors,
        setErrors,
    };

    /**
     * DATA LOAD
     */
    useEffect(() => {
        if (open) {
            if (implement) {
                setValues({
                    ...Object.fromEntries(
                        Object.entries(implement).map(([key, value]) => [key, value === null ? '' : value])
                    ),
                    id: implement.id,
                    state: implement.city?.state ?? '',
                    country: implement.city?.state?.country ?? '',
                    group: implement.subgroup?.group ?? '',
                    implement_brand: implement.asset_model?.brand ?? '',
                    owner: implement.owner ?? '',
                } as TData);
            } else {
                setValues(defaultValues);
            }
        }
    }, [implement, open]);

    /**
     * VALUES SUBMIT
     */
    const submitImplement = async () => {
        const data: Record<string, any> = {
            ...values,
        };

        // convert tire array
        data.tires = data.tires?.map((tire: AssetTire) => {
            const tireData: Record<string, any> = {
                ...tire,
                brand: tire.brand.id,
                tire: tire.tire.id,
            };
            if (tireData.id === 0) {
                delete tireData['id'];
            }
            return tireData;
        });

        // convert nested objects to id
        ['city', 'color', 'subgroup', 'trailer_type', 'asset_model', 'axle_type', 'owner'].forEach((key) => {
            if (key in data) {
                data[key] = !data[key] || data[key].id === 0 ? null : data[key].id;
            }
        });

        // clear empty fields
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });

        if (implement) {
            return await api.put(`/api/v0/fleet/implements/${implement.id}/`, data).then((response) => response.data);
        }

        return await api.post('/api/v0/fleet/implements/', data).then((response) => response.data);
    };

    const { mutate: handleSave, isPending } = useMutation({
        mutationFn: submitImplement,
        throwOnError: false,
        onError: (error: AxiosError<any>) => {
            try {
                let detail = null;
                let errors = error.response?.data as Record<string, string[]>;

                if (error.response?.data.hasOwnProperty('detail')) {
                    detail = errors.detail;
                    delete errors.detail;
                }

                let fieldErrors = concatFieldErrors(errors);

                setErrors(fieldErrors);

                if (detail) {
                    enqueueSnackbar(detail, { variant: 'error' });
                }
            } catch (e) {
                enqueueSnackbar('Erro ao salvar!', { variant: 'error' });
            }
        },
        onSuccess: () => {
            enqueueSnackbar('Implemento salvo com sucesso.', { variant: 'success' });
            setErrors({});
            onClose();
        },
    });

    /**
     * CREATE DIALOGS
     */
    const [modelOpen, setModelOpen] = useState(false);
    const [productOpen, setProductOpen] = useState(false);
    const [colorOpen, setColorOpen] = useState(false);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Dialog open={open} maxWidth={'lg'} fullWidth>
                <DialogContent sx={{ height: '90vh' }}>
                    <Stack direction={'column'} sx={{ pb: 3 }}>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            sx={{
                                pb: 2.5,
                            }}
                        >
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <ImplementIcon />
                                <Typography
                                    variant={'h6'}
                                    fontWeight={500}
                                    children={
                                        implement
                                            ? `Implemento ${implement.fleet_id} (${implement.license_plate})`
                                            : 'Novo Implemento'
                                    }
                                />
                            </Stack>

                            <Box>
                                <IconButton onClick={onClose} children={<CloseIcon />} disabled={isPending} />
                            </Box>
                        </Stack>

                        <ModalSection title={'Identificação do Implemento'} icon={ImplementInfoIcon} defaultExpanded>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'implement_brand.name',
                                            header: 'Marca',
                                            field: {
                                                type: 'search',
                                                name: 'implement_brand',
                                                endpoint: '/api/v0/registry/brands/',
                                                labelKey: (brand) => (brand ? brand.description : ''),
                                                queryParams: {
                                                    is_implement_brand: true,
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        readOnly={!values.implement_brand?.id}
                                        column={{
                                            accessorKey: 'asset_model.name',
                                            header: 'Modelo',
                                            field: {
                                                type: 'search',
                                                name: 'asset_model',
                                                endpoint: '/api/v0/fleet/models/',
                                                labelKey: (model) => (model ? model.description : ''),
                                                queryParams: {
                                                    asset_type: ASSET_TYPES.IMPLEMENT,
                                                    brand__id: values.implement_brand?.id,
                                                },
                                                inputAction: {
                                                    label: <AddIcon />,
                                                    onClick: () => setModelOpen(true),
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'color',
                                            header: 'Cor',
                                            field: {
                                                type: 'search',
                                                name: 'color',
                                                endpoint: '/api/v0/registry/colors/',
                                                labelKey: (color) => (color ? color.description : ''),
                                                inputAction: {
                                                    label: <AddIcon />,
                                                    onClick: () => setColorOpen(true),
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={1.5}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'manufacture_year',
                                            header: 'Ano Fabr.',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={1.5}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'model_year',
                                            header: 'Ano Modelo',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'chassi_id',
                                            header: 'Nº Chassi',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'serial_id',
                                            header: 'Nº de Produção',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </ModalSection>

                        <ModalSection title={'Documentação'} icon={DocsIcon} defaultExpanded>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'fleet_id',
                                            header: 'Frota',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudMaskText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'license_plate',
                                            header: 'Placa',
                                            field: {
                                                type: 'mask-text',
                                                mask: 'aaa-9*99',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'renavam',
                                            header: 'RENAVAM',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'empty_weight',
                                            header: 'Tara',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'legal_weight',
                                            header: 'PBT Legal',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={1} />
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'country',
                                            header: 'País',
                                            field: {
                                                type: 'search',
                                                name: 'country',
                                                endpoint: '/api/v0/locations/countries/',
                                                labelKey: (e) => (e ? e.description : ''),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        readOnly={!values.country?.id}
                                        column={{
                                            accessorKey: 'state',
                                            header: 'Estado',
                                            field: {
                                                type: 'search',
                                                name: 'state',
                                                endpoint: '/api/v0/locations/states/',
                                                labelKey: (e) => (e ? e.description : ''),
                                                queryParams: {
                                                    country__id: values.country?.id,
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        readOnly={!values.state?.id}
                                        column={{
                                            accessorKey: 'city',
                                            header: 'Cidade',
                                            field: {
                                                type: 'search',
                                                name: 'city',
                                                endpoint: '/api/v0/locations/cities/',
                                                labelKey: (e) => (e ? e.description : ''),
                                                queryParams: {
                                                    state__id: values.state?.id,
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </ModalSection>

                        <ModalSection title={'Classificação'} icon={ClassIcon}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'group.description',
                                            header: 'Grupo Operacional',
                                            field: {
                                                type: 'search',
                                                name: 'group',
                                                endpoint: '/api/v0/fleet/groups/',
                                                labelKey: (e) => (e ? e.description : ''),
                                                queryParams: {
                                                    asset_type: ASSET_TYPES.IMPLEMENT,
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        readOnly={!values.group?.id}
                                        column={{
                                            accessorKey: 'subgroup.description',
                                            header: 'Subgrupo',
                                            field: {
                                                type: 'search',
                                                name: 'subgroup',
                                                endpoint: '/api/v0/fleet/subgroups/',
                                                labelKey: (e) => (e ? e.description : ''),
                                                queryParams: {
                                                    group__id: values.group?.id,
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'trailer_type',
                                            header: 'Tipo de Rodado',
                                            field: {
                                                type: 'search',
                                                name: 'axle_type',
                                                endpoint: '/api/v0/fleet/axle-types/',
                                                labelKey: (e) => (e ? e.description : ''),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <CrudApiSelect
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'trailer_type',
                                            header: 'Tipo de Carroceria',
                                            field: {
                                                type: 'search',
                                                name: 'trailer_type',
                                                endpoint: '/api/v0/fleet/trailer-types/',
                                                labelKey: (e) => (e ? e.description : ''),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudSelect
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'property',
                                            header: 'Propriedade',
                                            field: {
                                                type: 'select',
                                                options: PROPERTY_TYPES_LABELS_MAP,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {values.property === PROPERTY_TYPES.THIRD_PARTY && (
                                        <CrudApiSelect
                                            {...valuesControl}
                                            column={{
                                                accessorKey: 'owner.name',
                                                header: 'Proprietário',
                                                field: {
                                                    type: 'search',
                                                    name: 'owner',
                                                    endpoint: '/api/v0/registry/entities/',
                                                    labelKey: (e) => (e ? e.name : ''),
                                                },
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </ModalSection>

                        <ModalSection title={'Informações'} icon={InfoIcon}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'axel_quantity',
                                            header: 'Qtd. Eixos',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'capacity',
                                            header: 'Lotação',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'volumetric_capacity',
                                            header: 'Capacidade Volumétrica',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'pallet_capacity',
                                            header: 'Capacidade Pallets',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </ModalSection>

                        <ModalSection title={'Aquisição'} icon={MoneyIcon}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'invoice_id',
                                            header: 'Nota Fiscal',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudDatePicker
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'purchase_date',
                                            header: 'Data',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudMask
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'purchase_value',
                                            header: 'Valor',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudText
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'purchase_depreciation',
                                            header: 'Depreciação',
                                            field: {
                                                type: 'number',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudDatePicker
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'warranty_date',
                                            header: 'Garantia',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudDatePicker
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'maintenance_plan_start',
                                            header: 'Início Plano Manutenção',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CrudDatePicker
                                        {...valuesControl}
                                        column={{
                                            accessorKey: 'maintenance_plan_end',
                                            header: 'Fim Plano Manutenção',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </ModalSection>

                        <ModalSection title={'Pneus'} icon={TiresIcon}>
                            <Stack rowGap={2}>
                                {values.tires.map((tire: AssetTire, index: number) => {
                                    const tireValuesControl = {
                                        values: tire,
                                        setValues: (v: AssetTire | ((v: AssetTire) => AssetTire)) => {
                                            setValues({
                                                ...values,
                                                tires: values.tires.map(
                                                    (t: AssetTire | ((v: AssetTire) => AssetTire), i: number) =>
                                                        i === index ? v : t
                                                ),
                                            });
                                        },
                                    };

                                    return (
                                        <Grid container spacing={2} key={index}>
                                            <Grid item xs={12} md={4}>
                                                <CrudApiSelect
                                                    {...tireValuesControl}
                                                    column={{
                                                        accessorKey: 'tire',
                                                        header: 'Pneu',
                                                        field: {
                                                            type: 'search',
                                                            name: 'tire',
                                                            endpoint: '/api/v0/catalog/products/',
                                                            labelKey: (e) => (e ? e.description : ''),
                                                            inputAction: {
                                                                label: <AddIcon />,
                                                                onClick: () => setProductOpen(true),
                                                            },
                                                            queryParams: {
                                                                category: CATEGORIES.TIRE,
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <CrudApiSelect
                                                    {...tireValuesControl}
                                                    column={{
                                                        accessorKey: 'brand',
                                                        header: 'Marca',
                                                        field: {
                                                            type: 'search',
                                                            name: 'brand',
                                                            endpoint: '/api/v0/registry/brands/',
                                                            labelKey: (e) => (e ? e.description : ''),
                                                            queryParams: {
                                                                is_tire_brand: true,
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <CrudText
                                                    {...tireValuesControl}
                                                    column={{
                                                        accessorKey: 'quantity',
                                                        header: 'Quantidade',
                                                        field: {
                                                            type: 'number',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <CrudText
                                                    {...tireValuesControl}
                                                    column={{
                                                        accessorKey: 'dot',
                                                        header: 'DOT',
                                                        field: {
                                                            type: 'number',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Stack
                                                    justifyContent={'center'}
                                                    sx={{
                                                        height: '100%',
                                                    }}
                                                >
                                                    <Box>
                                                        <IconButton
                                                            onClick={() =>
                                                                setValues({
                                                                    ...values,
                                                                    tires: values.tires.filter(
                                                                        (t: AssetTire, i: number) => i !== index
                                                                    ),
                                                                })
                                                            }
                                                            color={'error'}
                                                            children={<RemoveIcon />}
                                                        />
                                                    </Box>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                <Stack direction={'row'}>
                                    <Button
                                        variant={'outlined'}
                                        onClick={() =>
                                            setValues({
                                                ...values,
                                                tires: [...values.tires, defaultTire],
                                            })
                                        }
                                        startIcon={<AddIcon />}
                                        sx={{
                                            mt: 2,
                                        }}
                                        children={'Adicionar Pneu'}
                                    />
                                </Stack>
                            </Stack>
                        </ModalSection>
                    </Stack>
                </DialogContent>

                <DialogActions
                    sx={{
                        py: 1.5,
                        px: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderTop: '1px solid #E0E0E0',
                        bgcolor: '#F9F9F9',
                    }}
                >
                    <Box width={150}>
                        <CrudSelect
                            {...valuesControl}
                            column={{
                                accessorKey: 'is_active',
                                header: 'Status',
                                field: {
                                    size: 'small',
                                    type: 'select',
                                    options: {
                                        true: 'Ativo',
                                        false: 'Inativo',
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Stack direction={'row'} spacing={1} sx={{ p: 1 }}>
                        <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                        <Button
                            variant={'contained'}
                            children={'Salvar'}
                            onClick={() => handleSave()}
                            disabled={isPending}
                            startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                        />
                    </Stack>
                </DialogActions>
            </Dialog>

            {/* CREATE MODEL MODAL */}
            <CreateUpdateModal
                columns={modelColumns.map((c) => ({
                    enableEditing: true,
                    ...c,
                }))}
                open={modelOpen}
                setOpen={setModelOpen}
                endpoint={modelEndpoint}
                name={modelName}
            />

            {/* CREATE PRODUCTS MODAL */}
            <ProductModal open={productOpen} onClose={() => setProductOpen(false)} />

            {/* CREATE COLOR MODAL */}
            <CreateUpdateModal
                columns={colorColumns.map((c) => ({
                    enableEditing: true,
                    ...c,
                }))}
                open={colorOpen}
                setOpen={setColorOpen}
                endpoint={colorEndpoint}
                name={colorName}
            />
        </>
    );
};

export default ImplementModal;
