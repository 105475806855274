import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ExpandIcon from '@mui/icons-material/ExpandMore';

import { ModalSectionProps } from './ModalSection.d';

const ModalSection = (props: ModalSectionProps) => {
    const { icon: Icon, title, defaultExpanded } = props;

    return (
        <Accordion
            variant={'outlined'}
            disableGutters={true}
            defaultExpanded={defaultExpanded}
            sx={{
                border: 'none',
                width: '100%',
                '&:before': {
                    display: 'none',
                },
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandIcon />}
                sx={{
                    p: 0,
                }}
            >
                <Stack
                    spacing={1}
                    direction={'row'}
                    alignItems={'center'}
                    sx={{
                        color: '#555',
                        width: '100%',
                    }}
                >
                    <Icon fontSize={'small'} />
                    <Typography variant={'body2'} fontWeight={500} children={title} />
                    <Box flexGrow={1} p={1}>
                        <Divider sx={{ borderColor: '#E0E0E0' }} />
                    </Box>
                </Stack>
            </AccordionSummary>
            <AccordionDetails
                children={props.children}
                sx={{
                    px: 0,
                    pb: 2.5,
                }}
            />
        </Accordion>
    );
};

export default ModalSection;
