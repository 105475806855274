import React from 'react';

import Typography from '@mui/material/Typography';

const Footer = () => {
    return (<footer>
        <Typography sx={{my: 5}}>
            Desenvolvido por RelaySoft
        </Typography>
    </footer>);
};

export default Footer;
