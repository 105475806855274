import React, { useContext, useState } from 'react';

import dayjs from 'dayjs';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Clear';
import WorkorderIcon from '@mui/icons-material/ReceiptLong';
import SaveIcon from '@mui/icons-material/Save';

import api from 'src/services/api';
import { UserContext } from 'src/routes/privateRoutes';
import { TData } from 'src/components/crud/Crud.d';
import { concatFieldErrors } from 'src/components/crud/modals/CreateUpdateModal';

import CrudApiSelect from 'src/components/crud/fields/CrudApiSelect';
import CrudDatetimePicker from 'src/components/crud/fields/CrudDatetimePicker';
import CrudSelect from 'src/components/crud/fields/CrudSelect';
import CrudText from 'src/components/crud/fields/CrudText';

import { CATEGORIES, CATEGORIES_LABELS } from 'src/pages/maintenances/Workorder/Category.d';
import { ASSET_TYPES_LABELS_MAP } from 'src/pages/fleet/Fleet.d';

import { CreateWorkorderModalProps } from './CreateWorkorderModal.d';

const defaultValues = {
    id: 0,
    asset_type: '',
    asset: '',
    scheduled_date: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
    scheduled_hodometer: '',
    scheduled_hourmeter: '',
    category: CATEGORIES.PREVENTIVE,
    responsible: '',
    enterprise: '',
};

const CreateWorkorderModal = (props: CreateWorkorderModalProps) => {
    const { open, onClose, onCreate } = props;

    const { enqueueSnackbar } = useSnackbar();
    const user = useContext(UserContext);

    /**
     * VALUES CONTROL
     */
    const [values, setValues] = useState<TData>({
        ...defaultValues,
        responsible: user?.person ?? '',
    });
    const [errors, setErrors] = useState({});

    const valuesControl = {
        values,
        setValues,
        errors,
        setErrors,
    };

    /**
     * VALUES SUBMIT
     */
    const submit = async () => {
        const data: Record<string, any> = {
            ...values,
        };

        // convert nested objects to id
        ['asset', 'enterprise', 'responsible'].forEach((key) => {
            if (key in data) {
                data[key] = !data[key] || data[key].id === 0 ? null : data[key].id;
            }
        });

        // clear empty fields
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });

        return await api.post('/api/v0/maintenances/workorders/', data).then((response) => response.data);
    };

    const {
        mutate: handleSave,
        isPending,
        isError,
    } = useMutation({
        mutationFn: submit,
        throwOnError: false,
        onError: (error: AxiosError<any>) => {
            try {
                let detail = null;
                let errors = error.response?.data as Record<string, string[]>;

                if (error.response?.data.hasOwnProperty('detail')) {
                    detail = errors.detail;
                    delete errors.detail;
                }

                let fieldErrors = concatFieldErrors(errors);

                setErrors(fieldErrors);

                if (detail) {
                    enqueueSnackbar(detail, { variant: 'error' });
                }
            } catch (e) {
                enqueueSnackbar('Erro ao salvar!', { variant: 'error' });
            }
        },
        onSuccess: (data) => {
            enqueueSnackbar('Ordem de serviço criada com sucesso.', { variant: 'success' });
            setErrors({});
            onCreate(data);
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth>
            <DialogContent>
                <Stack direction={'column'} pb={3}>
                    <Stack direction={'row'} justifyContent={'space-between'} pb={2.5}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <WorkorderIcon />
                            <Typography variant={'h6'} fontWeight={500} children={'Criar ordem de serviço'} />
                        </Stack>

                        <Box>
                            <IconButton onClick={onClose} children={<CloseIcon />} disabled={isPending} />
                        </Box>
                    </Stack>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <CrudSelect
                                {...valuesControl}
                                column={{
                                    accessorKey: 'category',
                                    header: 'Categoria',
                                    field: {
                                        type: 'select',
                                        options: CATEGORIES_LABELS,
                                        default: CATEGORIES.PREVENTIVE,
                                    },
                                }}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid item xs={8} />
                        <Grid item xs={4}>
                            <CrudSelect
                                {...valuesControl}
                                column={{
                                    accessorKey: 'asset_type',
                                    header: 'Tipo de Ativo',
                                    field: {
                                        type: 'select',
                                        options: ASSET_TYPES_LABELS_MAP,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <CrudApiSelect
                                {...valuesControl}
                                column={{
                                    accessorKey: 'asset.description',
                                    header: 'Ativo',
                                    field: {
                                        type: 'search',
                                        name: 'asset',
                                        endpoint: '/api/v0/fleet/assets/',
                                        labelKey: (e) => (e ? e.description : ''),
                                        queryParams: {
                                            asset_type: values.asset_type,
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CrudApiSelect
                                {...valuesControl}
                                column={{
                                    accessorKey: 'enterprise.description',
                                    header: 'Empresa',
                                    field: {
                                        type: 'search',
                                        name: 'enterprise',
                                        endpoint: '/api/v0/registry/enterprises/',
                                        labelKey: (e) => (e ? e.name : ''),
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CrudApiSelect
                                {...valuesControl}
                                column={{
                                    accessorKey: 'responsible.description',
                                    header: 'Responsável',
                                    field: {
                                        type: 'search',
                                        name: 'responsible',
                                        endpoint: '/api/v0/registry/persons/',
                                        labelKey: (e) => (e ? e.name : ''),
                                        queryParams: {
                                            is_employee: true,
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <CrudDatetimePicker
                                {...valuesControl}
                                column={{
                                    accessorKey: 'scheduled_date',
                                    header: 'Data Agendamento',
                                    field: {
                                        type: 'datetime',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3.5}>
                            <CrudText
                                {...valuesControl}
                                column={{
                                    accessorKey: 'scheduled_hodometer',
                                    header: 'Hodômetro Agendamento',
                                    field: {
                                        type: 'number',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3.5}>
                            <CrudText
                                {...valuesControl}
                                column={{
                                    accessorKey: 'scheduled_hourmeter',
                                    header: 'Horímetro Agendamento',
                                    field: {
                                        type: 'number',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </DialogContent>

            <DialogActions
                sx={{
                    py: 1.5,
                    px: 3,
                    display: 'flex',
                    justifyContent: 'end',
                    borderTop: '1px solid #E0E0E0',
                    bgcolor: '#F9F9F9',
                }}
            >
                <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                <Button
                    variant={'contained'}
                    children={'Salvar'}
                    onClick={() => handleSave()}
                    disabled={isPending}
                    startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                />
            </DialogActions>
        </Dialog>
    );
};

export default CreateWorkorderModal;
