import React from 'react';

import { useSnackbar } from 'notistack';

import api from 'src/services/api';

import { TData } from 'src/components/crud/Crud.d';

import ConfirmModal from 'src/components/crud/modals/ConfirmModal';

import { DeleteModalProps } from './DeleteModal.d';

const DeleteModal = <T extends TData>(props: DeleteModalProps<T>) => {
    const {
        // STRUCTURE
        name,
        endpoint,

        // MODAL CONTROL
        modalEntity,
        setOpen,
        open,

        // ACTIONS
        refresh,
    } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * MODAL CONTROL
     */
    const handleDeleteCancel = () => {
        setOpen(false);
    };

    /**
     * ACTIONS
     */
    const handleDeleteSubmit = async (entity: T | null) => {
        if (!entity) {
            return;
        }

        return await api
            .delete(`${endpoint}${entity.id}/`)
            .then(() => {
                setOpen(false);
                refresh();
            })
            .catch((error: any) => {
                enqueueSnackbar(error?.response?.data?.detail ?? 'Erro ao apagar!', { variant: 'error' });
            });
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <ConfirmModal
            // States
            open={open}
            entity={modalEntity}
            // Texts
            title={`Apagar ${name.singular}?`}
            message={'Tem certeza que deseja apagar este registro?'}
            warningMessage={'Esta ação não poderá ser revertida!'}
            btnText={'Apagar'}
            // Events
            onSubmit={handleDeleteSubmit}
            onCancel={handleDeleteCancel}
        />
    );
};

export default DeleteModal;
