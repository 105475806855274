import React, { Fragment } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import ChangeableItem from 'src/pages/fleet/Allocation/CurrentAllocationModal/ChangeableItem';

import { EquipmentListProps } from './EquipmentList.d';

const EquipmentList = (props: EquipmentListProps) => {
    const { equipmentList, disabled, onAdd, onRemove } = props;

    /**
     * RENDER COMPONENT
     */
    return (
        <Stack direction={'column'} spacing={0.2} width={'90%'}>
            <Typography variant={'subtitle2'} children={'Equipamentos'} />
            <Card variant={'outlined'}>
                <Stack direction={'column'} alignItems={'center'} spacing={0.2}>
                    {equipmentList &&
                        equipmentList.map((equipment, index) => (
                            <Fragment key={index}>
                                <Box py={1}>
                                    <ChangeableItem
                                        asset={equipment}
                                        isFetched={true}
                                        isLoading={false}
                                        variant={'remove'}
                                        onClick={() => onRemove(equipment)}
                                    />
                                </Box>
                                <Divider variant={'middle'} flexItem={true} />
                            </Fragment>
                        ))}
                    <ListItem>
                        <Button
                            startIcon={<AddIcon />}
                            color={'success'}
                            fullWidth
                            disabled={disabled}
                            children={'Adicionar'}
                            onClick={() => onAdd()}
                        />
                    </ListItem>
                </Stack>
            </Card>
        </Stack>
    );
};

export default EquipmentList;
