import React, { SyntheticEvent, useEffect, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Clear';
import ProductIcon from '@mui/icons-material/OilBarrel';
import SaveIcon from '@mui/icons-material/Save';

import ApiSelect from 'src/components/fields/ApiSelect';
import getOptionLabel from 'src/components/utils/getOptionLabel';

import api from 'src/services/api';
import { TData } from 'src/components/crud/Crud.d';

import { ProductModalProps } from './ProductModal.d';

export const defaultValues = {
    id: 0,
    product: null,
    quantity: 1,
};

const ProductModal = (props: ProductModalProps) => {
    const { open, onClose, workorderId, onSave, modalEntity } = props;

    const [values, setValues] = useState<TData>(defaultValues);
    const isEditing = !!values.id;

    /**
     * DATA LOAD
     */
    useEffect(() => {
        setValues(open && modalEntity ? { ...modalEntity } : defaultValues);
    }, [modalEntity, open]);

    /**
     * SUBMIT ADD OR EDIT
     */
    const submit = async () => {
        if (!values) {
            return;
        }

        if (isEditing) {
            return await api.put(`/api/v0/maintenances/workorders/${workorderId}/supplies/${values.id}/`, {
                quantity: values.quantity,
            });
        }

        return await api.post(`/api/v0/maintenances/workorders/${workorderId}/supplies/`, {
            quantity: values.quantity,
            product: values.product.id,
        });
    };

    const { mutate: handleSave, isPending } = useMutation({
        mutationFn: submit,
        onSuccess: () => {
            onSave();
        },
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog
            fullWidth
            open={open}
            onClose={onClose}
            component={'form'}
            onSubmit={(e) => {
                e.preventDefault();
                handleSave();
            }}
        >
            <DialogContent>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    sx={{
                        pb: 2.5,
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <ProductIcon />
                        <Typography
                            variant={'h6'}
                            fontWeight={500}
                            children={isEditing ? 'Editar produto' : 'Adicionar produto'}
                        />
                    </Stack>

                    <Box>
                        <IconButton onClick={onClose} children={<CloseIcon />} disabled={isPending} />
                    </Box>
                </Stack>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ApiSelect
                            required
                            label={'Produto'}
                            endpoint={'/api/v0/catalog/products/'}
                            value={values.product}
                            readOnly={isEditing || isPending}
                            onChange={(event: SyntheticEvent, val) => {
                                setValues({
                                    ...values,
                                    product: val,
                                });
                            }}
                            getOptionLabel={(option) => getOptionLabel(option, 'description')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField
                            required
                            fullWidth
                            label={'Quantidade'}
                            type={'number'}
                            variant={'outlined'}
                            value={values.quantity}
                            disabled={isPending}
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    quantity: event.target.value,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    py: 1.5,
                    px: 3,
                    display: 'flex',
                    justifyContent: 'end',
                    borderTop: '1px solid #E0E0E0',
                    bgcolor: '#F9F9F9',
                }}
            >
                <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                <Button
                    variant={'contained'}
                    children={'Salvar'}
                    type={'submit'}
                    disabled={isPending}
                    startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ProductModal;
