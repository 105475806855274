import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import PasswordIcon from '@mui/icons-material/Password';

import api from 'src/services/api';
import appRoutes from 'src/routes/appRoutes';

import { User } from 'src/services/storage/user';
import { hasPermission } from 'src/services/storage/permissions';

import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import CrudPage from 'src/components/crud/CrudPage';
import CrudTableBtn from 'src/components/crud/CrudTableBtn';
import ConfirmModal from 'src/components/crud/modals/ConfirmModal';
import { Columns } from 'src/components/crud/Crud.d';

const columns: Columns<User> = [
    {
        accessorKey: 'email',
        header: 'E-mail',
    },
    {
        accessorKey: 'first_name',
        header: 'Nome',
    },
    {
        accessorKey: 'last_name',
        header: 'Sobrenome',
    },
    {
        accessorKey: 'password',
        header: 'Senha',
        hideColumn: true,
        field: {
            type: 'password',
        },
    },
    {
        accessorKey: 'password_confirm',
        header: 'Confirmação de Senha',
        hideColumn: true,
        field: {
            type: 'password',
        },
    },
];

const UsersPage = () => {
    /**
     * RESET PASSWORD CONFIRMATION
     */
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [modalUserEntity, setModalUserEntity] = useState<User | null>(null);

    const openResetPasswordModal = (entity: any = null) => {
        setModalUserEntity(entity);
        setResetPasswordModalOpen(true);
    };

    const handleResetPasswordCancel = () => {
        setResetPasswordModalOpen(false);
    };

    const handleResetPasswordSubmit = async (row: any) => {
        return await api
            .post(`/api/v0/users/password-reset/`, {
                email: row.email,
            })
            .then(() => {
                enqueueSnackbar('E-mail enviado com sucesso!', { variant: 'success' });
                setResetPasswordModalOpen(false);
            })
            .catch(() => {
                enqueueSnackbar('Erro ao enviar e-mail!', { variant: 'error' });
            });
    };

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Acessos', 'Usuários']} />
                </Box>
            </Stack>

            <CrudPage
                name={{
                    singular: 'Usuário',
                    plural: 'Usuários',
                }}
                route={appRoutes.Users}
                endpoint={'/api/v0/users/'}
                columns={columns}
                crudEnableDelete={false}
                crudEnableUpdate={false}
                rowActions={(entity: User) => {
                    if (!hasPermission('users.send_password_reset')) {
                        return null;
                    }

                    return (
                        <Stack direction={'row'}>
                            <CrudTableBtn
                                entity={entity}
                                onClick={openResetPasswordModal}
                                tooltip={'Redefinir Senha'}
                                icon={PasswordIcon}
                                color={'warning'}
                            />
                        </Stack>
                    );
                }}
            />

            {/** RESET PASSWORD MODAL */}
            <ConfirmModal
                // MODAL CONTROL
                open={resetPasswordModalOpen}
                entity={modalUserEntity}
                // CONTENT
                title={'Redefinir Senha'}
                message={'Deseja enviar um e-mail de redefinição de senha?'}
                btnText={'Enviar'}
                // EVENTS
                onSubmit={handleResetPasswordSubmit}
                onCancel={handleResetPasswordCancel}
            />
        </>
    );
};

export default UsersPage;
