import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import WarningRounded from '@mui/icons-material/WarningRounded';

import { TData } from 'src/components/crud/Crud.d';
import { ConfirmModalProps } from './ConfirmModal.d';
import { useMutation } from '@tanstack/react-query';

const ConfirmModal = <T extends TData>(props: ConfirmModalProps<T>) => {
    const { open, entity, title, btnText, onSubmit, onCancel, extraActions } = props;

    const [modalOpen, setModalOpen] = useState(false);

    /**
     * CONTROL OPEN STATE FROM OUTSIDE
     */
    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    /**
     * EVENT HANDLERS
     */
    const handleCancel = () => onCancel();

    const { mutate: handleSubmit, isPending } = useMutation({
        mutationFn: () => onSubmit(entity),
    });

    /**
     * DIALOG CONTENT
     */
    const dialogContent = () => {
        return (
            <>
                {'message' in props && <DialogContentText children={props.message} />}
                {'warningMessage' in props && (
                    <Stack
                        mt={2}
                        color={'red'}
                        spacing={0.5}
                        direction={'row'}
                        alignItems={'end'}
                        justifyContent={'flex-end'}
                    >
                        <WarningRounded fontSize={'small'} />
                        <Typography variant={'caption'} children={props.warningMessage} />
                    </Stack>
                )}
            </>
        );
    };

    /**
     * RENDER COMPONENT
     */
    return (
        <Dialog open={modalOpen} maxWidth={'xs'} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent children={'children' in props ? props.children : dialogContent()} />
            <DialogActions
                sx={{
                    p: '1.25rem',
                    display: 'flex',
                    backgroundColor: '#ECECEC',
                    justifyContent: extraActions ? 'space-between' : 'end',
                }}
            >
                {!!extraActions && <Stack children={extraActions} />}
                <Stack direction={'row'} spacing={1}>
                    <Button disabled={isPending} onClick={handleCancel} sx={{ color: 'black' }} children={'Cancelar'} />
                    <Button
                        disabled={isPending}
                        onClick={() => handleSubmit()}
                        color={'primary'}
                        variant={'contained'}
                        startIcon={isPending && <CircularProgress size={20} sx={{ mr: 2 }} />}
                        children={btnText}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal;
