import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Clear';
import CompleteIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/DeleteOutline';
import CancelIcon from '@mui/icons-material/Block';
import RequisitionIcon from '@mui/icons-material/Difference';
import SaveIcon from '@mui/icons-material/Save';

import api from 'src/services/api';
import getIdLabel from 'src/components/utils/getIdLabel';
import { concatFieldErrors } from 'src/components/crud/modals/CreateUpdateModal';

import { TData } from 'src/components/crud/Crud.d';
import CrudApiSelect from 'src/components/crud/fields/CrudApiSelect';
import CrudSelect from 'src/components/crud/fields/CrudSelect';
import CrudText from 'src/components/crud/fields/CrudText';

import { Item } from 'src/pages/catalog/products/Requisition/Requisition.d';
import { OPERATION, OperationCell } from 'src/pages/catalog/products/Requisition/Operation.d';
import { Status, STATUS } from 'src/pages/catalog/products/Requisition/Status.d';

import { RequisitionModalProps } from './RequisitionModal.d';

const defaultValues = {
    id: 0,
    entity: '',
    observation: '',
    operation: OPERATION.OUT,
    status: STATUS.PENDING,
    workorder: '',
};

const defaultItem: Item = {
    id: 0,
    product: '',
    quantity: 1,
    unit_cost: 0,
};

const RequisitionModal = (props: RequisitionModalProps) => {
    const { open, onClose, requisition } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * VALUES CONTROL
     */
    const [values, setValues] = useState<TData>(defaultValues);
    const [items, setItems] = useState<Item[]>([]);
    const [errors, setErrors] = useState<Record<string, any>>({});
    const [itemErrors, setItemErrors] = useState<Record<string, any>[]>([]);

    const isEditable = requisition?.status !== STATUS.DONE && requisition?.status !== STATUS.CANCELED;

    const valuesControl = {
        values,
        setValues,
        errors,
        setErrors,
        readOnly: !isEditable,
    };

    /**
     * DATA LOAD
     */
    useEffect(() => {
        if (open) {
            if (requisition) {
                setValues({ ...requisition } as TData);
                setItems([...requisition.items]);
            } else {
                setValues(defaultValues);
                setItems([defaultItem]);
            }
        }
    }, [open]);

    /**
     * VALUES SUBMIT
     */
    const submitChanges = async () => {
        const data: Record<string, any> = {
            ...values,
        };

        data.workorder = data?.workorder?.id;
        data.employee = data?.employee?.id;

        data.items = items.map((item) => ({
            product: item.product && item.product.id,
            unit_cost: parseFloat(item.unit_cost.toString()),
            quantity: parseFloat(item.quantity.toString()),
        }));

        // clear empty fields
        Object.keys(data).forEach((key) => {
            if (data[key] === '') {
                delete data[key];
            }
        });

        if (requisition) {
            return await api
                .put(`/api/v0/catalog/products/requisitions/${requisition.id}/`, data)
                .then((response) => response.data);
        }

        return await api.post('/api/v0/catalog/products/requisitions/', data).then((response) => response.data);
    };

    const { mutateAsync: handleSave, isPending } = useMutation({
        mutationFn: submitChanges,
        throwOnError: false,
        onError: (error: AxiosError<any>) => {
            try {
                let detail = null;
                let errors = error.response?.data as Record<string, any>;

                if (error.response?.data.hasOwnProperty('detail')) {
                    detail = Array.isArray(errors.detail) ? errors.detail.join(' ') : errors.detail;
                    delete errors.detail;
                }

                let fieldErrors = concatFieldErrors(errors);

                setErrors(fieldErrors);
                setItemErrors(errors?.items?.map((e: Record<string, any>) => concatFieldErrors(e)) ?? []);

                if (detail) {
                    enqueueSnackbar(detail, { variant: 'error' });
                }
            } catch (e) {
                enqueueSnackbar('Erro ao salvar!', { variant: 'error' });
            }
        },
        onSuccess: (res) => {
            enqueueSnackbar('Requisição salva com sucesso.', { variant: 'success' });
            setErrors({});
            onClose();
            console.log('onSuccess', res.data);
            return res.data;
        },
    });

    /**
     * STATUS SUBMIT
     */
    const submitStatus = async (status: Status, id?: number) => {
        const req_id = requisition?.id ?? id;
        return await api.patch(`/api/v0/catalog/products/requisitions/${req_id}/status/`, { status });
    };

    const { mutateAsync: handleStatusSubmit, isPending: isStatusPending } = useMutation({
        mutationFn: ({ status, id }: { status: Status; id?: number }) => submitStatus(status, id),
        throwOnError: false,
        onError: (error: AxiosError<any>) => {
            enqueueSnackbar(error.response?.data?.detail ?? 'Erro ao faturar!', { variant: 'error' });
        },
        onSuccess: () => {
            enqueueSnackbar('Status alterado com sucesso.', { variant: 'success' });
            setErrors({});
            onClose();
        },
    });

    const handleDoneSave = () => {
        handleSave().then((data) => {
            handleStatusSubmit({ status: STATUS.DONE, id: data.id });
        });
    };

    const handleCancelSave = () => {
        handleStatusSubmit({ status: STATUS.CANCELED });
    };

    const isLoading = isPending || isStatusPending;

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Dialog open={open} maxWidth={'md'} fullWidth>
                <DialogContent sx={{ height: '90vh' }}>
                    <Stack direction={'column'} pb={3} height={'100%'}>
                        <Stack direction={'row'} justifyContent={'space-between'} pb={2.5}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <RequisitionIcon />
                                <Typography
                                    variant={'h6'}
                                    fontWeight={500}
                                    children={
                                        requisition ? `Requisição ${getIdLabel(requisition.id)}` : 'Nova requisição'
                                    }
                                />
                            </Stack>

                            <Box>
                                <IconButton onClick={onClose} children={<CloseIcon />} disabled={isLoading} />
                            </Box>
                        </Stack>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <CrudSelect
                                    {...valuesControl}
                                    column={{
                                        accessorKey: 'operation',
                                        header: 'Operação',
                                        field: {
                                            type: 'select',
                                            options: Object.fromEntries(
                                                Object.entries(OPERATION).map(([key, value]) => [
                                                    value,
                                                    <OperationCell status={value} />,
                                                ])
                                            ),
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CrudApiSelect
                                    {...valuesControl}
                                    column={{
                                        accessorKey: 'workorder',
                                        header: 'Ordem de Serviço',
                                        field: {
                                            type: 'search',
                                            name: 'workorder',
                                            endpoint: '/api/v0/maintenances/workorders/',
                                            labelKey: (e) =>
                                                e.id ? `${getIdLabel(e.id)} (${e.asset.description})` : '',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <CrudApiSelect
                                    {...valuesControl}
                                    column={{
                                        accessorKey: 'employee.name',
                                        header: 'Solicitante',
                                        field: {
                                            type: 'search',
                                            name: 'employee',
                                            endpoint: '/api/v0/registry/persons/',
                                            labelKey: (e) => (e.id ? e.name : ''),
                                            queryParams: {
                                                is_employee: true,
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CrudText
                                    {...valuesControl}
                                    column={{
                                        accessorKey: 'observation',
                                        header: 'Observação',
                                        field: {
                                            type: 'text',
                                            multiline: true,
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Stack height={'100%'} py={2}>
                            <Box borderBottom={1} borderColor={'divider'}>
                                <Tabs value={0}>
                                    <Tab label={'Itens'} id={'0'} />
                                </Tabs>
                            </Box>

                            <Stack rowGap={2} pt={4}>
                                {items.map((item: Item, index: number) => {
                                    const itemValuesControl = {
                                        errors: itemErrors[index] || {},
                                        values: item,
                                        setValues: (v: Item | ((v: Item) => Item)) => {
                                            setItems(
                                                items.map((t: Item | ((v: Item) => Item), i: number) =>
                                                    i === index ? v : t
                                                ) as Item[]
                                            );
                                        },
                                        readOnly: !isEditable,
                                    };

                                    return (
                                        <Grid container spacing={2} key={index}>
                                            <Grid item xs={12} md={7}>
                                                <CrudApiSelect
                                                    {...itemValuesControl}
                                                    column={{
                                                        accessorKey: 'product',
                                                        header: 'Produto',
                                                        field: {
                                                            type: 'search',
                                                            name: 'product',
                                                            endpoint: '/api/v0/catalog/products/',
                                                            labelKey: (e) => (e ? e.description : ''),
                                                            queryParams: {
                                                                id__not_in: items
                                                                    .map((t) => t.product && t.product.id)
                                                                    .filter((t) => !!t)
                                                                    .join(','),
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <CrudText
                                                    {...itemValuesControl}
                                                    column={{
                                                        accessorKey: 'quantity',
                                                        header: 'Quantidade',
                                                        field: {
                                                            type: 'number',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                {values.operation.toString() === OPERATION.IN.toString() && (
                                                    <CrudText
                                                        {...itemValuesControl}
                                                        column={{
                                                            accessorKey: 'unit_cost',
                                                            header: 'Custo unitário',
                                                            field: {
                                                                type: 'number',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Stack justifyContent={'center'} height={'100%'}>
                                                    <Box>
                                                        <IconButton
                                                            disabled={!isEditable}
                                                            onClick={() =>
                                                                setItems(
                                                                    items.filter((t: Item, i: number) => i !== index)
                                                                )
                                                            }
                                                            color={'error'}
                                                            children={<RemoveIcon />}
                                                        />
                                                    </Box>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                <Stack direction={'row'}>
                                    <Button
                                        variant={'outlined'}
                                        onClick={() => setItems([...items, defaultItem])}
                                        startIcon={<AddIcon />}
                                        sx={{
                                            mt: 2,
                                        }}
                                        disabled={!isEditable}
                                        children={'Adicionar Produto'}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </DialogContent>

                <DialogActions
                    sx={{
                        px: 3,
                        minHeight: 75,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderTop: '1px solid #E0E0E0',
                        bgcolor: '#F9F9F9',
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        {values.status === STATUS.DONE && (
                            <Button
                                color={'error'}
                                variant={'outlined'}
                                children={'Cancelar Requisição'}
                                onClick={() => handleCancelSave()}
                                disabled={isLoading}
                                startIcon={isLoading ? <CircularProgress size={16} /> : <CancelIcon />}
                            />
                        )}
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isLoading} />
                        {values.status === STATUS.PENDING && (
                            <Button
                                color={'success'}
                                variant={'contained'}
                                children={'Salvar e faturar'}
                                onClick={() => handleDoneSave()}
                                disabled={isLoading}
                                startIcon={isLoading ? <CircularProgress size={16} /> : <CompleteIcon />}
                            />
                        )}
                        <Button
                            variant={'contained'}
                            children={'Salvar'}
                            onClick={() => handleSave()}
                            disabled={isLoading || !isEditable}
                            startIcon={isLoading ? <CircularProgress size={16} /> : <SaveIcon />}
                        />
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RequisitionModal;
