import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ModalSubsectionProps } from './ModalSubsection.d';

const ModalSubSection = (props: ModalSubsectionProps) => {
    const { icon: Icon, title } = props;

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            <Stack
                pb={1.5}
                spacing={1}
                direction={'row'}
                alignItems={'center'}
                sx={{
                    color: '#888',
                }}
            >
                <Icon fontSize={'small'} />
                <Typography variant={'body2'} fontWeight={500} children={title} sx={{ pr: 1 }} />
            </Stack>
            {props.children}
        </Box>
    );
};

export default ModalSubSection;
