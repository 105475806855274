import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import LinkIcon from '@mui/icons-material/Link';

import AppRoutes from 'src/routes/appRoutes';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns } from 'src/components/crud/Crud.d';

import { Allocation } from 'src/pages/fleet/Allocation/Allocation.d';

import AllocationModal from './AllocationModal';
import CurrentAllocationModal from './CurrentAllocationModal';

const endpoint = '/api/v0/fleet/allocations/';

const name = {
    singular: 'Alocação',
    plural: 'Alocações',
};

const columns: Columns<Allocation> = [
    {
        accessorKey: 'asset1.description',
        header: 'Ativo 1',
    },
    {
        accessorKey: 'asset2.description',
        header: 'Ativo 2',
    },
    {
        accessorKey: 'start_date',
        header: 'Início',
        type: 'datetime',
    },
    {
        accessorKey: 'end_date',
        header: 'Fim',
        type: 'datetime',
    },
];

const AllocationsPage = () => {
    /**
     * MODAL CONTROL
     */
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEntity, setModalEntity] = useState<Allocation | null>(null);
    const [refresh, setRefresh] = useState(false);

    /**
     * CURRENT ALLOCATION MODAL
     */
    const [currentAllocationModalOpen, setCurrentAllocationModalOpen] = useState(false);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} pt={4}>
                <Box mb={1}>
                    <Breadcrumb items={['Frota', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.Equipments}
                endpoint={endpoint}
                columns={columns}
                // Features
                controlledRefresh={refresh}
                setControlledRefresh={setRefresh}
                handleCreateUpdate={(entity: Allocation | null) => {
                    setModalOpen(true);
                    setModalEntity(entity);
                }}
                // Extra
                extraCrudActions={() => (
                    <Stack justifyContent={'center'}>
                        <Button
                            variant={'outlined'}
                            startIcon={<LinkIcon />}
                            onClick={() => setCurrentAllocationModalOpen(true)}
                            children={'Alocações atuais'}
                        />
                    </Stack>
                )}
            />

            <AllocationModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setModalEntity(null);
                    setRefresh(!refresh);
                }}
                allocation={modalEntity}
            />

            <CurrentAllocationModal
                open={currentAllocationModalOpen}
                onClose={() => setCurrentAllocationModalOpen(false)}
            />
        </>
    );
};

export default AllocationsPage;
