import { ItemRow } from 'src/pages/catalog/Plan/PlanModal/ItemsField';

export interface ItemSelectorModalProps {
    open: boolean;
    onClose: () => void;
    onAdd: (items: ItemRow) => void;
    selectedItems: ItemRow[];
}

export const ITEM_TYPES = {
    SERVICE: 'service',
    PRODUCT: 'product',
    BUNDLE: 'bundle',
};

export const ITEM_TYPE_LABELS = {
    [ITEM_TYPES.SERVICE]: 'Serviço',
    [ITEM_TYPES.PRODUCT]: 'Produto',
    [ITEM_TYPES.BUNDLE]: 'Conjunto',
};
