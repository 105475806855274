import React from 'react';

import { NumericFormat } from 'react-number-format';

import TextField from '@mui/material/TextField';

import { TData } from 'src/components/crud/Crud.d';
import getNestedValue from 'src/components/utils/getNestedValue';
import hasNestedValue from 'src/components/utils/hasNestedValue';

import { CrudMaskProps } from './CrudMask.d';

const CrudMask = <T extends TData>(props: CrudMaskProps<T>) => {
    const { column, values, setValues, errors, readOnly, required } = props;
    const { accessorKey } = column;

    const value = getNestedValue(values, column.accessorKey, '');

    return (
        <NumericFormat
            fullWidth
            label={column.header}
            name={column.accessorKey}
            value={value}
            disabled={readOnly}
            required={required}
            onValueChange={(formatValues, event) => {
                if (value !== formatValues.value) {
                    setValues({
                        ...values,
                        [accessorKey]: formatValues.value,
                    });
                }
            }}
            error={hasNestedValue(errors, column.accessorKey)}
            helperText={getNestedValue(errors, column.accessorKey, '')}
            // FORMAT
            prefix={'R$ '}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextField}
        />
    );
};

export default CrudMask;
