import React from 'react';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';

const Forbidden = () => {

    const navigate = useNavigate();

    return (<main>
        <Box
            height={'100vh'}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pb: 5,
            }}
        >
            <Box>
                <Typography sx={{pb: 2}}>
                    Permissão não encotrada =(
                </Typography>

                <Typography variant={'h3'} sx={{pb: 2}}>
                    Acesso negado
                </Typography>

                <Typography sx={{pb: 4}}>
                    Oops! Parece que você não tem permissão para acessar esta página.<br/>
                    Se você acha que o problema é conosco, por favor nos avise!
                </Typography>

                <Button
                    onClick={() => navigate('/')}
                    variant={'contained'}
                    size={'large'}
                >
                    <ChevronLeftIcon/>
                    Voltar
                </Button>
            </Box>
        </Box>
    </main>);
};

export default Forbidden;
