import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';
import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Columns } from 'src/components/crud/Crud.d';

import { Service } from 'src/pages/catalog/services/Service/Service.d';
import ServiceModal from './ServiceModal';

export const name = {
    singular: 'Serviço',
    plural: 'Serviços',
};

export const columns: Columns<Service> = [
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
];

export const endpoint = '/api/v0/catalog/services/';

const ServicesPage = () => {
    /**
     * MODAL CONTROL
     */
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEntity, setModalEntity] = useState<Service | null>(null);
    const [refresh, setRefresh] = useState(false);

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box sx={{ mb: 1 }}>
                    <Breadcrumb items={['Manutenção', 'Cadastros', name.plural]} />
                </Box>
            </Stack>

            <CrudAPI
                // Structure
                name={name}
                route={AppRoutes.Services}
                endpoint={endpoint}
                columns={columns}
                // Features
                controlledRefresh={refresh}
                setControlledRefresh={setRefresh}
                handleCreateUpdate={(entity: Service | null) => {
                    setModalOpen(true);
                    setModalEntity(entity);
                }}
            />

            <ServiceModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setModalEntity(null);
                    setRefresh(!refresh);
                }}
                service={modalEntity}
            />
        </>
    );
};

export default ServicesPage;
