import React, { useEffect, useMemo, useState } from 'react';

import CheckboxTree, { Node } from 'react-checkbox-tree';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import Typography from '@mui/material/Typography';

import CheckedIcon from '@mui/icons-material/CheckBox';
import UncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import HalfCheckIcon from '@mui/icons-material/IndeterminateCheckBox';
import ExpandLessIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import ExpandMoreIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import EmptyIcon from '@mui/icons-material/InboxRounded';
import ExpandAllIcon from '@mui/icons-material/UnfoldMoreRounded';
import CollapseAllIcon from '@mui/icons-material/UnfoldLessRounded';

import api from 'src/services/api';
import Center from 'src/components/Center';
import Error from 'src/components/Error';
import Loader from 'src/components/Loader';

import { RemapNodeKeys } from 'src/components/fields/SelectTree/SelectTree.d';

import { CheckTreeProps } from './CheckTree.d';

export const remapGroupNodes = (nodes: any, remapKeys: RemapNodeKeys[], accKey = 'node') => {
    return nodes
        .map((node: any) => {
            const [remapKey, ...restKeys] = remapKeys;

            const newNode: Node = {
                value: remapKey.childrenKey ? `${accKey}-${node.id}` : node.id.toString(),
                label: node[remapKey.labelKey],
            };

            if (remapKey.childrenKey) {
                newNode.children = remapGroupNodes(node[remapKey.childrenKey], restKeys, newNode.value);
            }

            return newNode;
        })
        .filter((node: Node) => (node.children && node.children.length) || !node.value.includes(accKey));
};

const addHiddenClass = (nodes: Node[], search: string): Node[] => {
    return nodes.map((node) => {
        const matchesSearch = node.label?.toString().toLowerCase().includes(search);
        const newChildren = node.children ? addHiddenClass(node.children, search) : [];

        // Check if any child matches the search
        const hasVisibleChildren = newChildren.some((child) => {
            return !child.className || !child.className.includes('hidden');
        });

        const newNode = { ...node };

        if (newChildren.length) {
            newNode.children = newChildren;
        }

        return {
            ...newNode,
            className: matchesSearch || hasVisibleChildren ? '' : 'hidden',
        };
    });
};

const CheckTree = (props: CheckTreeProps) => {
    const { onCheck, checked, itemsEndpoint, checkedItemsEndpoint, remapKeys, enabled = true, search } = props;

    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'checkedNodes',
        });
    }, [checkedItemsEndpoint]);

    /**
     * LOAD NODES
     */
    const {
        data,
        isFetching: isNodesLoading,
        isError: isNodesError,
        isSuccess: isNodesSuccess,
    } = useQuery({
        enabled: enabled,
        queryKey: ['nodes', itemsEndpoint],
        queryFn: async () =>
            await api
                .get(itemsEndpoint)
                .then((res) => [
                    ...remapGroupNodes(res.data.tree, remapKeys),
                    ...remapGroupNodes(res.data.orphans, remapKeys.slice(-1)),
                ]),
        placeholderData: (prev) => prev,
    });

    const nodes = useMemo(() => {
        if (!data) {
            return [];
        }

        if (!search) {
            return data;
        }

        return addHiddenClass(data, search.toLowerCase());
    }, [data, search]);

    /**
     * LOAD CHECKED NODES
     */
    const {
        data: checkedItems,
        isFetching: isItemsLoading,
        isError: isItemsError,
    } = useQuery({
        enabled: enabled && !!checkedItemsEndpoint && isNodesSuccess,
        initialData: [],
        queryKey: ['checkedNodes', checkedItemsEndpoint],
        queryFn: async () => {
            return await api.get(checkedItemsEndpoint ?? '').then((res) => res.data.results);
        },
    });

    useEffect(() => {
        if (checkedItems) {
            onCheck(checkedItems.map((item: any) => item.id.toString()));
        }
    }, [checkedItems]);

    /**
     * COMPONENT CONTROL
     */
    const [expanded, setExpanded] = useState<string[]>([]);

    /**
     * RENDER COMPONENT
     */
    if (isNodesLoading || isItemsLoading) {
        return (
            <Loader text={isNodesLoading ? 'Carregando grupos de produtos...' : 'Carregando produtos do conjunto...'} />
        );
    }

    if (isNodesError || isItemsError) {
        return (
            <Error
                showBackButton={false}
                title={isNodesError ? 'Erro ao carregar grupos de produtos' : 'Erro ao carregar produtos do conjunto'}
                subtitle={'Tente novamente mais tarde.'}
            />
        );
    }

    if (!nodes.length) {
        return (
            <Center>
                <EmptyIcon
                    sx={{
                        fontSize: 40,
                        color: 'text.secondary',
                    }}
                />
                <Typography variant={'body2'} color={'textSecondary'} children={'Nenhum item encontrado.'} />
            </Center>
        );
    }

    return (
        <CheckboxTree
            nodes={nodes ?? []}
            checked={checked}
            onCheck={onCheck}
            expanded={expanded}
            onExpand={(expanded) => setExpanded(expanded)}
            showNodeIcon={false}
            showExpandAll={true}
            icons={{
                check: <CheckedIcon />,
                uncheck: <UncheckedIcon />,
                halfCheck: <HalfCheckIcon />,
                expandClose: <ExpandLessIcon />,
                expandOpen: <ExpandMoreIcon />,
                expandAll: <ExpandAllIcon />,
                collapseAll: <CollapseAllIcon />,
            }}
        />
    );
};

export default CheckTree;
