import React from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Status, STATUS, STATUS_COLORS, STATUS_ICONS, STATUS_LABELS } from 'src/pages/maintenances/Workorder/Status.d';

import { PageFiltersProps } from './PageFilters.d';

const PageFilters = (props: PageFiltersProps) => {
    const { setExtraFilters, extraFilters, statusCount } = props;

    const selectedFilter = extraFilters.find((f) => f.id === 'status')?.value;

    /**
     * RENDER COMPONENT
     */
    return (
        <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography color={'textSecondary'} children={'Filtros:'} />
                <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                    {Object.values(STATUS).map((filter: Status) => {
                        const Icon = STATUS_ICONS[filter];
                        const selected = selectedFilter === filter;

                        return (
                            <Tooltip key={filter} title={STATUS_LABELS[filter]} placement={'top'} arrow={true}>
                                <Button
                                    size={'small'}
                                    disableElevation
                                    color={STATUS_COLORS[filter]}
                                    variant={selected ? 'contained' : 'text'}
                                    onClick={() => {
                                        setExtraFilters((oldFilters) => {
                                            const newFilters = oldFilters.filter((f) => f.id !== 'status');
                                            return selected
                                                ? newFilters
                                                : [...newFilters, { id: 'status', value: filter }];
                                        });
                                    }}
                                    sx={{
                                        px: 0.8,
                                        py: 0.3,
                                        minWidth: 0,
                                        '& .MuiButton-startIcon': {
                                            mr: 0.5,
                                        },
                                    }}
                                    startIcon={
                                        <Icon
                                            sx={{
                                                color: selected ? 'inherit' : `${STATUS_COLORS[filter]}.light`,
                                            }}
                                        />
                                    }
                                    children={statusCount ? statusCount[filter] ?? 0 : 0}
                                />
                            </Tooltip>
                        );
                    })}
                </Stack>
                <Divider flexItem orientation={'vertical'} variant={'middle'} />
            </Stack>
        </Stack>
    );
};

export default PageFilters;
